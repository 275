import { getAssets } from "../services/api";
import { assetDataActions } from "../store/assets";

export const getAllAssets = (toast, dispatch, headers) => {
    const getCMSResponse = (response) => {
        if (response.result === 'SUCCESS') {
            const assetData = response.data;
            dispatch(assetDataActions.storeAssets(assetData))
        } else if (response.result === "FAILED" && response.error) {
            const error = response.error;
            toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
        }
    };
    getAssets(headers, dispatch, getCMSResponse);
}
