import React, { useState,useMemo } from 'react';
import * as PR from "../../../prime-modules/index";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { uploadAssetsApi } from "../../../services/api"
import { useDispatch, useSelector } from 'react-redux';
import "./About.scss";
import { trimFormData, validateTextField } from '../../../utils';
import { getAllAssets } from '../../../genericFunctions/getAssets';
import ImagePreview from '../generic/imagePreview';
import { uploadWidthHeightImage } from '../../../utils/reuse';

const About = ({assets, disableSave}) => {
    const dispatch = useDispatch();
    const toast = React.useRef(null)
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);

    const [connectivityImage, setConnectivityImage] = useState(null);
    const [instantActivationImage, setInstantActivationImage] = useState(null);
    const [globalCoverageImage, setGlobalCoverageImage] = useState(null);
    const [attractiveRatesImage, setAttractiveRatesImage] = useState(null);
    const [formImage, setFormImage] = useState(null);

    const validationSchema = Yup.object().shape({
        AB0001: validateTextField(7, 20, 'Header'),
        AB0002: validateTextField(25, 350, 'Description'),
        AB0004: validateTextField(20, 50, 'Unlock World Header'),
        AB0005: validateTextField(150, 260, 'Unlock World Description'),
        AB0006: validateTextField(7, 20, 'Instant Activation Header'),
        AB0007: validateTextField(200, 320, 'Instant Activation Description'),
        AB0010: validateTextField(7, 20, 'Global Coverage Header'),
        AB0011: validateTextField(200, 320, 'Global Coverage Description'),
        AB0012: validateTextField(7, 20, 'Attractive Rates Header'),
        AB0013: validateTextField(200, 320, 'Attractive Rates Description'),
        AB0015: validateTextField(20, 60, 'Header'),
        AB0016: validateTextField(20, 60, 'Description'),
        AB0017: Yup.string().trim().email("Invalid Email").required("Email required"),
    });

    const handleSubmit = async (data) => {  
        const formData = new FormData();
        const trimmedFormData = trimFormData(data);
        formData.append('screen', 'aboutUs');
        for (const field in trimmedFormData) {
            if (trimmedFormData.hasOwnProperty(field)) {
                const value = trimmedFormData[field];
                if (value && typeof value === 'string' && !(value.startsWith('iVBORw0KGgoAAAANSUhEUg') || value.startsWith('/9j/4AAQSkZJRgA'))) {
                    formData.append(field, value);
                }
            } 
        }
        const getCMSResponse = (response) => {
            if (response.result === 'SUCCESS') {
                if(response.data === 'success'){
                  toast.current.show({ severity: 'success', summary: 'Success', detail: ' Updated successfully' });
                }
                getAllAssets(toast, dispatch, headers);
            } else if (response.result === "FAILED" && response.error) {
                const error = response.error;
                toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
        };
        uploadAssetsApi(formData, headers, dispatch, getCMSResponse);
    }

    const formik = useFormik({
        initialValues: assets,
        validationSchema: validationSchema,
        enableReinitialize: true, // Reinitialize form when assets change
        onSubmit: handleSubmit
    })

    return (
        <div className='contact'>
            <div className="card">
                {/* <h2>About Us Settings</h2> */}
                <form autoComplete="off" onSubmit={formik.handleSubmit} >
                    <div className="imageFormat mb-4 ">
                        <strong>(Image Size Should not exceed 1MB)</strong>
                    </div>
                    <div className="grid grid-nogutter align-items-center mb-4 mt-4">
                        <div className='col-2'>
                            <label htmlFor="AB0001" className="cmsLabels">Top Header</label>
                        </div>
                        <div className="col-5">
                            <div className="file-upload-section">
                                <PR.InputText
                                    id="AB0001"
                                    name="AB0001"
                                    aria-describedby="AB0001"
                                    value={formik.values.AB0001}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            {formik.touched.AB0001 && formik.errors.AB0001 ? (
                                <div className='error-msg'>{formik.errors.AB0001}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="grid grid-nogutter align-items-center mb-4">
                        <div className='col-2'>
                            <label htmlFor="AB0002" className="cmsLabels">Top Header Description</label>
                        </div>
                        <div className="col-5">
                            <div className="file-upload-section">
                                <PR.InputTextarea autoResize
                                    id="AB0002"
                                    name="AB0002"
                                    aria-describedby="AB0002"
                                    value={formik.values.AB0002}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                            </div>
                            {formik.touched.AB0002 && formik.errors.AB0002 ? (
                                <div className='error-msg'>{formik.errors.AB0002}</div>
                            ) : null}
                        </div>
                    </div>
                    <PR.Divider />
                    <div className="card">
                        <div className="grid grid-nogutter">
                            <div className="col-12">
                                <label htmlFor="UnlockWorld" className="mb-2"><h3 className="mt-1 mb-2">Unlock a World of Seamless Connectivity Section</h3></label>
                            </div>
                        </div>
                        <div className="grid grid-nogutter mt-2">
                            <div className="col-6">
                                <div className="grid grid-nogutter align-items-center">
                                    <div className='col-3'>
                                        <label htmlFor="AB0003" className="cmsLabels">Image <span className='imageFormat'>(png, jpeg, 514W X 343H)</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                type="file"
                                                name="AB0003"
                                                className="file-upload-input"
                                                accept="image/png, image/jpeg"
                                                onChange={async(event) => {
                                                    const file = event.currentTarget.files[0];
                                                    setConnectivityImage(file);
                                                    formik.handleChange(event);
                                                    uploadWidthHeightImage(file, "AB0003", toast, dispatch, headers, 'aboutUs')
                                                }}
                                                onBlur={formik.handleBlur}
                                            />
                                            {connectivityImage && formik.touched.AB0003 && formik.errors.AB0003 ? (
                                                <div className='error-msg'>{formik.errors.AB0003}</div>
                                            ) : null}
                          
                                            <ImagePreview formikError={formik.errors.AB0003} defaultImage={assets.AB0003} newImage={connectivityImage}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="grid grid-nogutter align-items-center mb-4">
                                    <div className='col-3'>
                                        <label htmlFor="AB0004" className="cmsLabels">Header</label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                id="AB0004"
                                                name="AB0004"
                                                aria-describedby="AB0004"
                                                value={formik.values.AB0004}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        {formik.touched.AB0004 && formik.errors.AB0004 ? (
                                            <div className='error-msg'>{formik.errors.AB0004}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="grid grid-nogutter align-items-center mb-3">
                                <div className='col-3'>
                                        <label htmlFor="AB0005" className="cmsLabels">Description</label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputTextarea autoResize
                                               id="AB0005"
                                               name="AB0005"
                                               aria-describedby="AB0005"
                                               value={formik.values.AB0005}
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur} />
                                        </div>
                                        {formik.touched.AB0005 && formik.errors.AB0005 ? (
                                            <div className='error-msg'>{formik.errors.AB0005}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <PR.Divider />
                        <div className="grid grid-nogutter">
                            <div className="col-12">
                                <label htmlFor="instantActivationSection" className="mb-2"><h3 className="mt-1 mb-2">Instant Activation Section</h3></label>
                            </div>
                        </div>
                        <div className="grid grid-nogutter mt-3">
                            <div className='col-6'>
                                <div className="grid grid-nogutter align-items-center mb-4">
                                    <div className='col-3'>
                                        <label htmlFor="AB0006" className="cmsLabels">Header</label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                id="AB0006"
                                                name="AB0006"
                                                aria-describedby="AB0006"
                                                value={formik.values.AB0006}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        {formik.touched.AB0006 && formik.errors.AB0006 ? (
                                            <div className='error-msg'>{formik.errors.AB0006}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="grid grid-nogutter align-items-center mb-3">
                                    <div className='col-3'>
                                        <label htmlFor="AB0007" className="cmsLabels">Description</label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputTextarea autoResize
                                                id="AB0007"
                                                name="AB0007"
                                                aria-describedby="AB0007"
                                                value={formik.values.AB0007}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur} />
                                        </div>
                                        {formik.touched.AB0007 && formik.errors.AB0007 ? (
                                            <div className='error-msg'>{formik.errors.AB0007}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="grid grid-nogutter align-items-center mb-4">
                                    <div className='col-3'>
                                        <label htmlFor="AB0008" className="cmsLabels">Image <span className='imageFormat'>(png, jpeg, 514W X 343H)</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                type="file"
                                                name="AB0008"
                                                className="file-upload-input"
                                                accept="image/png image/jpeg"
                                                onChange={async(event) => {
                                                    const file = event.currentTarget.files[0];
                                                    setInstantActivationImage(file);
                                                    formik.handleChange(event); 
                                                    uploadWidthHeightImage(file, "AB0008", toast, dispatch, headers, 'aboutUs')
                                                }}
                                                onBlur={formik.handleBlur}
                                            />
                                            {instantActivationImage && formik.touched.AB0008 && formik.errors.AB0008 ? (
                                                <div className='error-msg'>{formik.errors.AB0008}</div>
                                            ) : null}

                                            <ImagePreview formikError={formik.errors.AB0008} defaultImage={assets.AB0008} newImage={instantActivationImage}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <PR.Divider />
                        <div className="grid grid-nogutter">
                            <div className="col-12">
                                <label htmlFor="UnlockWorld" className="mb-2"><h3 className="mt-1 mb-2">Global Coverage Section</h3></label>
                            </div>
                        </div>
                        <div className="label-input-wrapper grid grid-nogutter mt-3">
                            <div className="col-6">
                                <div className="grid grid-nogutter align-items-center mb-4">
                                    <div className='col-3'>
                                        <label htmlFor="AB0009" className="cmsLabels">Image  <span className='imageFormat'>(png, jpeg, 514W X 343H)</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                type="file"
                                                name="AB0009"
                                                className="file-upload-input"
                                                accept="image/png image/jpeg"
                                                onChange={async(event) => {
                                                    const file = event.currentTarget.files[0];
                                                    setGlobalCoverageImage(file);
                                                    formik.handleChange(event);
                                                    uploadWidthHeightImage(file, "AB0009", toast, dispatch, headers, 'aboutUs')
                                                }}
                                                onBlur={formik.handleBlur}
                                            />
                                             {globalCoverageImage && formik.touched.AB0009 && formik.errors.AB0009 ? (
                                                <div className='error-msg'>{formik.errors.AB0009}</div>
                                            ) : null}

                                            <ImagePreview formikError={formik.errors.AB0009} defaultImage={assets.AB0009} newImage={globalCoverageImage}/>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='col-6'>
                                <div className="grid grid-nogutter align-items-center mb-4">
                                    <div className='col-3'>
                                        <label htmlFor="AB0010" className="cmsLabels"><span>Header</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                id="AB0010"
                                                name="AB0010"
                                                aria-describedby="AB0010"
                                                value={formik.values.AB0010}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        {formik.touched.AB0010 && formik.errors.AB0010 ? (
                                            <div className='error-msg'>{formik.errors.AB0010}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="grid grid-nogutter align-items-center mb-3">
                                    <div className='col-3'>
                                        <label htmlFor="AB0011" className="cmsLabels"><span>Description</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputTextarea autoResize
                                                id="AB0011"
                                                name="AB0011"
                                                aria-describedby="AB0011"
                                                value={formik.values.AB0011}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur} />
                                        </div>
                                        {formik.touched.AB0011 && formik.errors.AB0011 ? (
                                            <div className='error-msg'>{formik.errors.AB0011}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <PR.Divider />
                        <div className="grid grid-nogutter">
                            <div className="col-12">
                                <label htmlFor="panelIcons" className="mb-2"><h3 className="mt-1 mb-2">Attractive Rates Section</h3></label>
                            </div>
                        </div>
                        <div className="grid grid-nogutter mt-3">
                            <div className='col-6'>
                                <div className="grid grid-nogutter align-items-center mb-4">
                                    <div className='col-3'>
                                        <label htmlFor="AB0012" className="cmsLabels"><span>Header</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                id="AB0012"
                                                name="AB0012"
                                                aria-describedby="AB0012"
                                                value={formik.values.AB0012}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        {formik.touched.AB0012 && formik.errors.AB0012 ? (
                                            <div className='error-msg'>{formik.errors.AB0012}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="grid grid-nogutter align-items-center mb-3">
                                    <div className='col-3'>
                                        <label htmlFor="AB0013" className="cmsLabels"><span>Description</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputTextarea autoResize
                                                id="AB0013"
                                                name="AB0013"
                                                aria-describedby="AB0013"
                                                value={formik.values.AB0013}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur} />
                                        </div>
                                        {formik.touched.AB0013 && formik.errors.AB0013 ? (
                                            <div className='error-msg'>{formik.errors.AB0013}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="grid grid-nogutter align-items-center">
                                    <div className='col-3'>
                                        <label htmlFor="AB0014" className="cmsLabels"><span>Image</span> <span className='imageFormat'>(png, jpeg, 514W X 343H)</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                type="file"
                                                name="AB0014"
                                                className="file-upload-input"
                                                accept="image/png image/jpeg"
                                                onChange={async(event) => {
                                                    const file = event.currentTarget.files[0];
                                                    setAttractiveRatesImage(file);
                                                    formik.handleChange(event);
                                                    uploadWidthHeightImage(file, "AB0014", toast, dispatch, headers, 'aboutUs')
                                                }}
                                                onBlur={formik.handleBlur}
                                            />
                                            {attractiveRatesImage && formik.touched.AB0014 && formik.errors.AB0014 ? (
                                                <div className='error-msg'>{formik.errors.AB0014}</div>
                                            ) : null}

                                            <ImagePreview formikError={formik.errors.AB0014} defaultImage={assets.AB0014} newImage={attractiveRatesImage}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <PR.Divider />
                        
                        <div className="grid grid-nogutter">
                            <div className="col-12">
                                <label htmlFor="panelIcons" className="mb-0"><h3 className="mt-1 mb-2">Form Section</h3></label>
                            </div>
                        </div>
                        <div className="grid grid-nogutter mt-3">
                            <div className='col-6'>
                                <div className="grid grid-nogutter align-items-center mb-4">
                                    <div className='col-3'>
                                        <label htmlFor="AB0015" className="cmsLabels"><span>Header</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                id="AB0015"
                                                name="AB0015"
                                                aria-describedby="AB0015"
                                                value={formik.values.AB0015}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        {formik.touched.AB0015 && formik.errors.AB0015 ? (
                                            <div className='error-msg'>{formik.errors.AB0015}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="grid grid-nogutter align-items-center mb-4">
                                    <div className='col-3'>
                                        <label htmlFor="AB0016" className="cmsLabels"><span>Description</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputTextarea autoResize
                                                id="AB0016"
                                                name="AB0016"
                                                aria-describedby="AB0016"
                                                value={formik.values.AB0016}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur} />
                                        </div>
                                        {formik.touched.AB0016 && formik.errors.AB0016 ? (
                                            <div className='error-msg'>{formik.errors.AB0016}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="grid grid-nogutter align-items-center mb-3">
                                    <div className='col-3'>
                                        <label htmlFor="AB0017" className="cmsLabels"><span>Email</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                id="AB0017"
                                                name="AB0017"
                                                aria-describedby="AB0017"
                                                value={formik.values.AB0017}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        {formik.touched.AB0017 && formik.errors.AB0017? (
                                            <div className='error-msg'>{formik.errors.AB0017}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="grid grid-nogutter align-items-center">
                                    <div className='col-3'>
                                        <label htmlFor="AB0018" className="cmsLabels"><span>Image</span> <span className='imageFormat'>(png, jpeg, 1040W X 941H)</span></label>
                                    </div>
                                    <div className="col-8">
                                        <div className="file-upload-section">
                                            <PR.InputText
                                                type="file"
                                                name="AB0018"
                                                className="file-upload-input"
                                                accept="image/png image/jpeg"
                                                onChange={async(event) => {
                                                    const file = event.currentTarget.files[0];
                                                    setFormImage(file);
                                                    formik.handleChange(event);
                                                    uploadWidthHeightImage(file, "AB0018", toast, dispatch, headers, 'aboutUs')
                                                }}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formImage && formik.touched.AB0018 && formik.errors.AB0018 ? (
                                                <div className='error-msg'>{formik.errors.AB0018}</div>
                                            ) : null}

                                            <ImagePreview formikError={formik.errors.AB0018} defaultImage={assets.AB0018} newImage={formImage}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <PR.Divider />
                    </div>

                    <div className="grid grid-nogutter">
                        <div className="col-12">
                            <PR.Button label="Save" type='submit' className="saveBtn" disabled={disableSave} />
                        </div>
                    </div>

                </form>
            </div>
            <PR.Toast ref={toast} position="top-right" />
        </div>
    );
};
export default About;