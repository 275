import React, { useMemo, useEffect, useCallback, useState, useRef } from "react";
import AdminFooter from "../layout/admin-footer";
import AdminHeader from "../layout/admin-header";
import * as PR from "../../prime-modules/index";
import { useSelector, useDispatch } from 'react-redux';
import { getSiteMaintenance, updateSiteMaintenance } from "../../services/api";
import "../settings/SiteMaintenance.scss"; // CSS
import * as utils from '../../utils';

const SiteMaintenance = () => {
    const dispatch = useDispatch();
    const toast = useRef();
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);
    const [mode, setMode] = useState({});
    const [loading, setLoading] = useState(false);

    const getSitemaintenanceConfig = useCallback(async () => {
        getSiteMaintenance(headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                response.data.status = response.data.isUnderMaintenance ? 'Deactivate Maintenance' : 'Activate Maintenance';
                setMode(response.data);
            } else {
                const error = response.error;
                toast.current.show({ severity: error.severity, summary:'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
            setLoading(false);
        })
    }, [dispatch, headers, setMode])

    useEffect(() => {
        setLoading(true);
        getSitemaintenanceConfig();
    }, [getSitemaintenanceConfig])

    const setSitemaintenanceConfig = () => {
        setLoading(true);
        const reqObj = {
            isUnderMaintenance: !mode.isUnderMaintenance
        }
        updateSiteMaintenance(reqObj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Updated sucessfully'})
                response.data.status = response.data.isUnderMaintenance ? 'Deactivate Maintenance' : 'Activate Maintenance';
                setMode(response.data);
            } else {
                const error = response.error;
                toast.current.show({ severity: error.severity, summary:'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
            setLoading(false);
        })
    }

    return (
    <>
        <div className="main">
            <div className="layout-sidebar">
                <AdminHeader />
            </div>
            <div className="layout-content-wrapper">
                <section className="admin-users-section">
                    <div className="grid grid-nogutter">
                        <div className="col-6">
                            <div className="heading-sec flex align-items-center justify-content-between">
                                <div>
                                    <h1>Site Maintenance</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="users-data-table card maintenance-mode">
                        <div className="grid-nogutter order-details-sec">
                            <div className="card flex justify-content-center mb-3">
                                <p><b>Updated On : </b> {utils.modifyDateTime(mode?.updatedTs)}</p>
                            </div>
                            <div className="card flex flex-wrap justify-content-center gap-3 mb-3">
                                <PR.Button type="button" label={mode?.status} className={mode?.status ?? 'greyDefault'} loading={loading} onClick={setSitemaintenanceConfig}/>
                            </div>
                        </div>
                    </div>
                    <PR.Toast ref={toast} position='top-right'/>
                </section>
                <AdminFooter/>
            </div>
        </div>
    </>
  );
};

export default SiteMaintenance;