import { useState } from "react";
import * as PR from "../../prime-modules/index";
import { filterCountryName } from "../../utils/reuse";

const RoamingColumn = ({ data }) => {
  const [showCountries, setShowCountries] = useState(false);

  const countries = data?.bundle?.countries || [];
  const countryName = countries.map(
    (country) => country.country?.name || "Unknown"
  );
  const roamingCountries = data?.bundle?.roamingcountries || [];
  const countriesList = roamingCountries
    .map((item) => item.country.name)
    .sort((a, b) => a.localeCompare(b));

  return (
    <div>
      {roamingCountries.length === 0 && <span>{filterCountryName(countryName.join(", "))}</span>}
      {roamingCountries.length === 1 && <span>{filterCountryName(countriesList[0])}</span>}

      {roamingCountries.length > 1 && (
        <span>
          {`${roamingCountries.length} Countries`}
          {/* Info icon */}
          <i
            className="pi pi-info-circle ml-2"
            onClick={() => setShowCountries(!showCountries)}
            style={{ cursor: "pointer" }}
            title="Click to view countries"
          />
        </span>
      )}

      {/* Dialog for showing countries list */}
      {showCountries && (
        <PR.Dialog
          header="Roaming Countries"
          visible={showCountries}
          onHide={() => setShowCountries(false)}
          dismissableMask
          style={{ width: "60vw" }} // Increase the width
          className="roaming-countries"
        >
          <div style={{ columnCount:'3' }}>
            {countriesList.map((country, index) => (
              <div key={index} className="countries-list">
                <span
                  style={{
                    display: "inline-block",
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                    backgroundColor: "black",
                    marginRight: "5px",
                  }}
                />{" "}
                {filterCountryName(country)}
              </div>
            ))}
          </div>
        </PR.Dialog>
      )}
    </div>
  );
};

export default RoamingColumn;
