import { globalConfig } from "../GlobalConfig";
import moment from 'moment';
import * as Yup from 'yup';

export const setPrice = (price) => {
  return price ? globalConfig.defaultCurrency + setNumberPrecision(price)  : globalConfig.defaultCurrency + '0.00';
};
export const providerPrice = (price) => {
  return price && '$' + setNumberPrecision(price);
};

export const setNumberPrecision = (val) => {
  return val && val.toFixed(2);
};

export const setCartTotal = (cartObj) => {
  let totalOrderAmount = 0;
  Object.keys(cartObj).map((key) => (totalOrderAmount += cartObj[key].price));
  return totalOrderAmount;
};


export const modifyDateTime = (orderDate) => {
  const year = new Date(orderDate).getFullYear();
  return (year !== 1) ? moment(orderDate).utc().format('YYYY-MM-DD HH:mm:ss') : '-';
}

export const modifyDashboardDateFormat = (orderDate) => {
  const year = new Date(orderDate).getFullYear();
  return (year !== 1) ? moment(orderDate).format('MMM-YY') : '-';
}

export const modifyDashboardDate = (date) => {
  const year = new Date(date).getFullYear();
  return (year !== 1) ? moment(date).format('YYYY-MM-DD') : '';
}

export const modifyBundlesSoldDateFormat = (orderDate) => {
  const year = new Date(orderDate).getFullYear();
  return (year !== 1) ? moment(orderDate).format("MMM 'YY") : '';
}


export const modifyDate = (orderDate) => {
  const year = new Date(orderDate).getFullYear();
  return (year !== 1) ? moment(orderDate).format('MM-DD-YYYY') : '';
}

export const removeWhiteSpaces = (e) => {
  if (e.key === " ") {
    e.currentTarget.value = e.currentTarget.value.replace(/\s+/g, "").trim();
  }
};

export const blockInvalidChar = (e) => {
  ['-'].includes(e.key) && e.preventDefault();
}

// Function to calculate progress and percentage
export const calculateProgressAndPercentage = (prevCount, currentCount) => {
  if (prevCount && currentCount) {
    const diff = currentCount - prevCount;
    const progressStatus = (diff === 0) ? "normal" : diff > 0 ? "up" : "down";
    const percentage = ((diff / prevCount) * 100).toFixed(0);
    return [progressStatus, percentage];
  } else {
    return [];
  }

}

export const trimFormData = (formData) => {
  const trimmedFormData = {};
  Object.keys(formData).forEach((key) => {
    if (typeof formData[key] === 'string') {
      trimmedFormData[key] = formData[key].trim();
    } else {
      trimmedFormData[key] = formData[key];
    }
  });
  return trimmedFormData;
}

export const handlePhoneChange = (event, formik, fieldName) => {
  // Remove all non-numeric and non-plus characters from the input string
  let cleaned = event.target.value.replace(/[^0-9+]/g, '').substring(0, globalConfig.allowPhoneNumberLength);

  // Check if the cleaned string starts with a plus sign
  if (cleaned.startsWith('+')) {
    // If there is another plus sign after the first character, remove it
    const secondPlusIndex = cleaned.indexOf('+', 1);
    if (secondPlusIndex !== -1) {
      cleaned = cleaned.slice(0, secondPlusIndex) + cleaned.slice(secondPlusIndex + 1);
    }
  } else {
    // If the cleaned string doesn't start with a plus sign, remove all plus signs
    cleaned = cleaned.replace(/\+/g, '');
  }

  // Update the value of the 'phone' field in the form with the cleaned phone number
  formik.setFieldValue(fieldName, cleaned);
};

export const handleSetNameLength = (value, length) => {
  return value.trim().substring(0, length)
}

export const formatDate = (t) => {
  return moment(t).format('DD-MM-YYYY')
}


export const formatCalendarDate = (date) => {
  /*const newDate = date.split("T"); 
  const dateComponents = newDate[0].split("-"); 
  const year = parseInt(dateComponents[0], 10); 
  const month = parseInt(dateComponents[1], 10)-1; 
  const day = parseInt(dateComponents[2], 10);*/
  return new Date(date);
}

export const firstLetterCapital = (value) => value.charAt(0).toUpperCase() + value.slice(1)

// Function to get image dimensions
export const getImageDimensions = (file) => {
  return new Promise((resolve, reject) => {
    if (!file || !(file instanceof File)) {
      reject('No valid file provided');
      return;
    }

    if (!file.type.startsWith('image/')) {
      reject('File is not an image');
      return;
    }

    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = () => reject('Failed to load image');
    img.src = URL.createObjectURL(file);
  });
};

export const validateTextField = () => {
  return Yup.string().trim().required('Please enter the text')
}

export const checkImageValidation = (widthValue, heightValue) => {
  return Yup.mixed().nullable().required('An image file is required').test(
    'fileFormat',
    'Unsupported file format',
    (value) => {
      if (!value || typeof value === 'string') return true;
      return ['image/jpeg', 'image/png'].includes(value.type);
    }
  )
    .test(
      'fileDimensions',
      `Image must be ${widthValue}W x ${heightValue}H`,
      async (value) => {
        if (!value || typeof value === 'string') return true;
        try {
          const dimensions = await getImageDimensions(value);
          return dimensions.width === widthValue && dimensions.height === heightValue;
        } catch (error) {
            return false
        }
      }
    )
}

// Function to deeply compare two objects
export const deepEqual = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
};

// Function to check if a value is an object
export const isObject = (value) => {
  return value !== null && typeof value === 'object';
};

export const convertStringToArray = (inputString, labels = {}) => {
  try {
      const parsedArray = JSON.parse(inputString);
      return parsedArray.map((item) => {
          const id = item;
          return { id: id, name: labels[item] ?? item};
      });
  } catch (error) {
      return [];
  }
};

export const convertArrayToString = (inputArray) => {
   return inputArray.map(item => item.id);

};

//function to convert the object into a query string
export const serializeParams = (params) => {
  return Object.keys(params)
      .filter(key => {
          const value = params[key];
          return value !== null && value !== undefined && value !== '' && (!Array.isArray(value) || value.length > 0);
      })
      .map(key => {
          const value = params[key];
          if (Array.isArray(value)) {
              return `${encodeURIComponent(key)}=${encodeURIComponent(value.join(','))}`;
          }
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join('&');
};

// Function to extract only required key values
export const extractRequiredKeys = (array, keys) => {
  return array.map(obj => {
    let newObj = {};
    keys.forEach(key => {
      if (obj.hasOwnProperty(key)) {
        newObj[key] = obj[key];
      }
    });
    return newObj;
  });
};
