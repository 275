import { createSlice } from '@reduxjs/toolkit';

const adminSessionData = localStorage.getItem('adminSessionData') !== null ? JSON.parse(localStorage.getItem('adminSessionData')) : {};
const adminLoggedIn = localStorage.getItem('adminSessionData') !== null ? true : false;

const initialAuthState = {
    adminLoggedIn: adminLoggedIn,
    adminSessionData: adminSessionData,
};

const adminAuthSlice = createSlice({
    name: 'adminAuthentication',
    initialState: initialAuthState,
    reducers: {
        onLogin(state, action) {
            const storedLoginInfo = {
                sessionId: action.payload.sessionId,
                username: action.payload.userName,
                email: action.payload.email
            }
            state.adminLoggedIn = true;
            state.adminSessionData = storedLoginInfo;
            localStorage.setItem('adminSessionData', JSON.stringify(storedLoginInfo));
        },
        onLogout(state) {
            state.adminLoggedIn = false;
            state.adminSessionData = {};
            localStorage.removeItem('adminSessionData');
        },
    },
});

export const adminAuthActions = adminAuthSlice.actions;

export default adminAuthSlice.reducer;