import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import * as PR from "../../prime-modules/index";
import AdminFooter from "../layout/admin-footer.jsx";
import AdminHeader from "../layout/admin-header.jsx";
import { globalConfig } from "../../GlobalConfig";
import * as Yup from "yup";
import {
  getBatchList,
  getBatchInfo,
  createBatch,
  getPriceFilters,
  getCatalogsList,
  getCountriesList,
  updateBatch
} from "../../services/api.jsx";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import moment from "moment";
import { useFormik } from "formik";
import * as utils from "../../utils";
import RoamingColumn from "./RoamingInfo.jsx";
import { formatPromoCode, filterCountryName, setDataAmount } from "../../utils/reuse.js";
import "./PrepaidRetailVouchers.scss";

const PrepaidRetailVouchers = () => {
  const dispatch = useDispatch();
  const adminData = useSelector((state) => state.adminAuth.adminSessionData);
  const headers = useMemo(() => {
    return { sessionid: adminData.sessionId };
  }, [adminData.sessionId]);
  const toast = useRef();

  const [expandedRows, setExpandedRows] = useState(null);
  const [visible, setVisible] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [createVoucher, setCreateVoucher] = useState(false);
  const [prepaidList, setPrepaidList] = useState([]);
  const [selectedBundles, setSelectedBundles] = useState([]);
  const [provider, setProvider] = useState("");
  const [filterprovider, setFilterProvider] = useState("");

  const [uniqueRegions, setUniqueRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);

  const [allCountries, setAllCountries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("GB");

  const [filterSelectedRegion, setFilterSelectedRegion] = useState(null);
  const [filterCountries, setFilterCountries] = useState([]);
  const [filterCountry, setFilterCountry] = useState("GB");

  const [displayBundle, setDisplayBundle] = useState(false);

  const [filters, setFilters] = useState({});
  const [createFilters, setCreateFilters] = useState({});

  const [batchOptions, setBatchOptions] = useState([]);

  const [bundles, setBundles] = useState([]);

  const [totalVouchers, setTotalVouchers] = useState(1);

  const [filterBatchName, setFilterBatchName] = useState(null);
  const [filterStatus, setFilterStatus] = useState("");

  const [loading, setLoading] = useState(true);
  const [catalogLoading, setCatalogLoading] = useState(false)

  const [getBatchName, setGetBatchName] = useState("");
  const [getStatus, setGetStatus] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const initialFilters = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    batchName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    promoCode: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  const [searchFilter, setSearchFilter] = useState(initialFilters);

  const [selectedInfoBatch, setSelectedInfoBatch] = useState(null);
  const [showBatchInfoDialog, setShowBatchInfoDialog] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(true);
  const itemsPerPage = 20;

  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState(-1);
  const [offset, setOffset] = useState(0)

  const [disable, setDisable] = useState(false)
  const [disableText, setDisableText] = useState("")
  const [batchData, setBatchData] = useState([])

  const handleExportCSV = (rowData) => {
    if (!rowData?.vouchers || rowData?.vouchers?.length === 0) {
      toast.current?.show({
        severity: 'warn',
        summary: 'Warning',
        detail: 'No vouchers available to export',
      });
      return;
    }

    const vouchers = rowData.vouchers.map((voucher) => ({
      BatchName: rowData?.batchName,
      Description: voucher?.description ? voucher?.description : '-',
      Data: setDataAmount(voucher),
      Duration: voucher?.duration ? `${voucher?.duration} days` : '-',
      Price: voucher?.ourPrice ? `${globalConfig.defaultCurrency} ${voucher?.ourPrice}` : '-',
      Status: voucher?.vouchersnapshot?.status === 'USED' ? 'REDEEMED' : voucher?.vouchersnapshot?.status,
      VoucherCode: formatPromoCode(voucher?.vouchersnapshot?.promoCode),
    }));

    if (vouchers.length > 0) {
      const csvContent =
        'data:text/csv;charset=utf-8,' +
        ['Batch Name', 'Description', 'Data', 'Duration', 'Price', 'Status', 'Voucher Code'].join(',') +
        '\n' +
        vouchers
          .map((entry) =>
            Object.values(entry)
              .map((value) => (typeof value === 'string' && value.includes(',') ? `"${value}"` : value))
              .join(',')
          )
          .join('\n');

      const encodedUri = encodeURI(csvContent);

      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `${rowData.batchName}-prepaid-vouchers.csv`);
      document.body.appendChild(link);
      link.click();
    }
  };

  const batchName = (rowData) => {
    return (
      <div className="batch-column">
        <div className="card flex align-items-center gap-3">
          <p>
            {rowData?.batchName}{" "}
          </p>
          <i
            title="View Details"
            className="pi pi-info-circle"
            style={{ cursor: 'pointer' }}
            onClick={() => handleBatchInfoClick(rowData)}
          ></i>
          <i
            className="pi pi-file"
            title="Export CSV"
            style={{ cursor: "pointer" }}
            onClick={() => handleExportCSV(rowData)}
          ></i>
          {/* <i className="pi pi-trash"></i> */}
        </div>
      </div>
    );
  };

  const handleOrderDetails = (orderId) => {
    if (orderId && orderId !== null) {
      // Redirecting to new tab (Order detail screen)
      window.open(`/orders/details/${orderId}`, "_blank");
    }
  }

  const rowExpansionTemplate = (rowData) => {
    const vouchers = rowData?.vouchers; // Get vouchers excluding the first one (shown in main row)

    const renderVoucherStatus = (voucher) => {
      const voucherStatus = voucher?.vouchersnapshot?.status;
      if (!voucherStatus || voucherStatus?.length === 0) {
        return <span className="status expired">N/A</span>; // Handle cases where there are no vouchers
      }
      switch (voucherStatus.toLowerCase()) {
        case "published":
          return <span className="status published">Published</span>;
        case "active":
          return <span className="status active">Active</span>;
        case "pending":
          return <span className="status pending">Pending</span>;
        case "expired":
          return <span className="status expired">Expired</span>;
        case "used":
          return <span className="status redeemed">Redeemed</span>;
        case "invalid":
          return <span className="status expired">Invalid</span>;
        default:
          return <span className="status none">Unknown Status</span>;
      }
    };

    return (
      <div>
        {vouchers && vouchers?.length > 0 ? (
          <PR.DataTable
            value={vouchers}
            stripedRows
            className="vouchers-expandable-table"
            rowClassName={(rowData) => {
              let className = "";
              if (rowData?.vouchersnapshot?.disabled === true) {
                className += "disabled-row ";
              }
              return className.trim();
            }}
          >
            {/* Render shared bundle details */}
            <PR.Column header="" style={{ width: "3%" }} />
            <PR.Column
              header=""
              style={{ width: "23%" }}
              className="batch-column"
            />
            <PR.Column
              header="Plan"
              body={(e) => e?.description ? filterCountryName(e?.description) : "-"}
              style={{ width: "22%" }}
            />
            <PR.Column
              header="Duration"
              body={(e) => (e.duration ? `${e.duration} days` : "-")}
              style={{ width: "8%" }}
            />
            <PR.Column
              header="Data"
              body={(e) => setDataAmount(e)}
              style={{ width: "8%" }}
            />
            <PR.Column
              header="Price"
              body={(e) =>
                e.ourPrice ? `${globalConfig.defaultCurrency} ${e.ourPrice}` : "-"
              }
              style={{ width: "7%" }}
            />

            <PR.Column
              header="Status"
              body={(voucher) => renderVoucherStatus(voucher)}
              style={{ width: "8%" }}
            />
            <PR.Column
              header="Voucher Code"
              className="voucherCode"
              field="promoCode"
              body={(rowData) => (
                <div className="voucherCode">
                  <span>{formatPromoCode(rowData?.vouchersnapshot?.promoCode) || 'N/A'}</span>
                  <i
                    title="Copy Voucher Code"
                    className="pi pi-copy"
                    style={{
                      color: 'red',
                      marginRight: '8px',
                      cursor: 'pointer'
                    }}
                    onClick={() => copyToClipboard(formatPromoCode(rowData?.vouchersnapshot?.promoCode))}
                  />
                </div>
              )}
              style={{ width: "17%" }}
            />
            <PR.Column
              body={({ vouchersnapshot, orderids }) => (
                vouchersnapshot?.status === "USED" && orderids !== null ? (
                  <div className="flex align-items-center">
                    <span className="details-btn">
                      <i
                        className="pi pi-chevron-right"
                        onClick={() => handleOrderDetails(orderids[0])}
                      />
                    </span>
                  </div>
                ) : null // Return null instead of empty fragment
              )}
              className="last-column"
              style={{ width: "2%" }}
            />
          </PR.DataTable>
        ) : (
          <p>No additional vouchers</p>
        )}
      </div>
    );
  };

  const providerList = [
    { name: "eSIM-Go" },
    { name: "Airalo" },
    // { name: "Cobira" },
  ];

  const handleBundleSelection = (bundle, isChecked) => {
    setSelectedBundles((prev) => {
      if (isChecked) {
        return [...prev, { discountBundle: bundle.name, count: 0 }];
      } else {
        return prev.filter((b) => b.discountBundle !== bundle.name);
      }
    });
  };

  const handleBatchInfoClick = (rowData) => {
    const req = {
      id: rowData?.batchName,
      status: rowData?.vouchers[0]?.vouchersnapshot?.status,
    };
    getBatchInfo(req, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        setSelectedInfoBatch(response.data);
        setShowBatchInfoDialog(true);
        setDisableText(rowData?.vouchers[0]?.vouchersnapshot?.disabled === true ? 'Enable Batch' : 'Disable Batch')
      } else {
        const error = response?.error;
        toast.current?.show({
          severity: error.severity,
          summary: "Error",
          detail: error?.errorMsg ? error?.errorMsg : error?.summary,
        });
      }
    });
  };

  const onSortChange = (field) => {
    const newSortOrder = sortField === field && sortOrder === 1 ? -1 : 1;
    setSortField(field);
    setSortOrder(newSortOrder);
    getBatch(field, newSortOrder === 1 ? 'asc' : 'desc');
  }

  const handleSubmit = (values, { setSubmitting }) => {
    const currentDate = new Date();
    const endDate = new Date();
    endDate.setFullYear(currentDate.getFullYear() + 3);
    const formData = {
      discountType: "prepaidbundle",
      ...values,
      startTs: currentDate.toISOString(),
      endTs: endDate.toISOString(),
      discounts: selectedBundles,
      maxNrOfUses: 1,
    };
    const totalSelectedCounts = selectedBundles.reduce((sum, b) => sum + (b.count || 0), 0);
    if (totalSelectedCounts !== totalVouchers) {
      toast.current.show({ 
        severity: 'warn', 
        summary: 'Total mismatch', 
        detail: 'Total voucher count does not match selected vouchers.', 
        life: 3000 
      });
      setSubmitting(false);
      return;
    }
    searchHandler(values);
    setSubmitting(false);
    const getResponse = (response) => {
      if (response.result === "SUCCESS") {
        setCreateVoucher(false);
        getBatch()
        formik.resetForm()
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Created Batch successfully",
        });
      } else {
        const error = response.error;
        toast.current.show({
          severity: error.severity,
          summary: "Error",
          detail: error.errorMsg ? error.errorMsg : error.summary,
        });
      }
      resetData();
    };
    createBatch(formData, headers, dispatch, getResponse);
  };

  const getBatch = useCallback(async (sortField = 'createdAt', sortOrder = 'desc') => {
    setLoading(true);
    const defaultFilters = {
      offset: offset,
      limit: itemsPerPage,
      orderBy: sortField,
      direction: sortOrder
    };
    const queryParams = utils.serializeParams({
      ...filters,
      ...defaultFilters,
    });
    let getResponse = (response) => {
      setLoading(false);
      if (response.result === "SUCCESS") {
        const promoCodes = response.data ? response.data : [];
        const promoCodesList = promoCodes.filter(p => (p.name !== '' && p.bundles.length !== 0))
        setTotalRecords(promoCodes.length === itemsPerPage);
        if (promoCodesList.length > 0) {
          setPrepaidList(promoCodesList);
          if (!queryParams.includes('batchId')) {
            const batchOptions = promoCodesList.map((item) => ({
              name: item.name,
              value: item.name,
            }))

            setBatchOptions(batchOptions);
          }

        } else {
          setLoading(false);
          toast.current.show({
            severity: "warn",
            summary: "Warning",
            detail: "No records found",
          });
        }
      } else {
        setPrepaidList([]);
        setLoading(false);
        const error = response.error;
        toast.current?.show({
          severity: error.severity,
          summary: "Error",
          detail: error.errorMsg ? error.errorMsg : error.summary,
        });
      }
    };
    getBatchList(queryParams, headers, dispatch, getResponse);
  }, [dispatch, headers, filters, offset]);

  useEffect(() => {
    getBatch();
  }, [getBatch]);

  const getCatalogs = useCallback(async () => {
    setCatalogLoading(true);
    const defaultFilters = {
      sortingOrder: "asc",
      sortingField: "providerprice",
      country,
    };
    const queryParams = utils.serializeParams({
      ...createFilters,
      ...defaultFilters,
    });
    const handleResponse = (response) => {
      setCatalogLoading(false);
      if (response.result === "SUCCESS") {
        let catalogList = response.data || [];
        catalogList = catalogList?.filter(c => c.ourPrice !== -1)
        if (Array.isArray(catalogList) && catalogList?.length > 0) {
          setBundles(catalogList);
          setDisplayBundle(true);
        } else {
          setBundles([]);
          toast.current.show({
            severity: "warn",
            summary: "Warning",
            detail: "No plans for the selected",
          });
        }
      } else {
        setBundles([]);
        const error = response?.error;
        toast.current?.show({
          severity: error.severity,
          summary: "Error",
          detail: error?.errorMsg || error?.summary,
        });
      }
    };
    getCatalogsList(queryParams, headers, dispatch, handleResponse);
  }, [createFilters, country, headers, dispatch]);

  // useEffect(() => {
  //   getCatalogs()
  // }, [getCatalogs]);

  const searchHandler = useCallback(() => {
    setCreateFilters((prevFilters) => ({
      ...prevFilters,
      country,
      region: selectedRegion,
      providerName: provider ? provider.map((item) => item?.name).join(",") : "",
    }));
    getPriceFilters(headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
      } else {
        const error = response?.error;
        toast.current?.show({
          severity: error.severity,
          summary: "Error",
          detail: error?.errorMsg ? error?.errorMsg : error?.summary,
        });
      }
    });
  }, [country, selectedRegion, provider, dispatch, headers]);

  const getSearchHandler = useCallback(() => {
    const searchStatus = getStatus?.length ? getStatus.join(",") : null;
    setOffset(0)
    setCurrentPage(1)
    setFilters((prevFilters) => ({
      ...prevFilters,
      batchId: getBatchName,
      status: searchStatus,
    }));
  }, [getBatchName, getStatus]);

  useEffect(() => {
    if (
      createFilters.country ||
      createFilters.region ||
      createFilters.provider
    ) {
      getCatalogs();
    }
  }, [createFilters, getCatalogs, headers, dispatch]);

  useEffect(() => {
    const handleCountriesResponse = (response) => {
      if (response.result === "SUCCESS") {
        const countriesData = response.data || [];
        if (countriesData.length > 0) {
          const countriesList = countriesData.map(({ name, region, iso }) => ({
            name: name.split("(")[0].trim(),
            region,
            iso,
          }));
          setAllCountries(countriesList);
          setCountries(countriesList);
          setFilterCountries(countriesList);
          const uniqueRegionList = [
            ...new Set(countriesList.map((item) => item.region)),
          ].map((region) => ({ label: region, value: region }));
          const unemptyRegionList = uniqueRegionList
            ?.filter((region) => region.label && region.value)
            .sort((a, b) => a.label.localeCompare(b.label)); // Removed empty object from the region list
          setUniqueRegions(unemptyRegionList);
        }
      }
    };
    getCountriesList(headers, dispatch, handleCountriesResponse);
  }, [dispatch, headers]);

  const selectBox = (bundle) => {
    const isSelected =
      Array.isArray(selectedBundles) &&
      selectedBundles?.some(
        (selected) => selected?.discountBundle === bundle?.name
      );
    return (
      <PR.Checkbox
        checked={isSelected}
        onChange={(e) => handleBundleSelection(bundle, e.checked)}
      />
    );
  };

  const globalSearchHandler = (e) => {
    const value = e.target.value;
    let _filters = { ...searchFilter };
    _filters["global"].value = value;
    setSearchFilter(_filters);
    setSearchValue(value);
  };

  const handleRegionSelection = (region) => {
    setSelectedRegion(region);
    setFilterSelectedRegion(region);
    const countriesByRegion = region
      ? allCountries?.filter((c) => c.region === region)
      : allCountries;
    setCountries(countriesByRegion);
    setFilterCountries(countriesByRegion);
    const countryDisplay = countriesByRegion?.find((c) => c.iso === country)
      ? country
      : null;
    setCountry(region ? countryDisplay : "GB");
    setSelectedBundles([]);
  };

  const handleCountrySelection = (e) => {
    const selectedCountry = e.value;
    setCountry(selectedCountry);
    setFilterCountry(selectedCountry);
    setSelectedBundles([]);
  };

  const regionFilterHandler = (region) => {
    setFilterSelectedRegion(region);
    const countriesByRegion = region
      ? allCountries?.filter((c) => c.region === region)
      : allCountries;
    const countryDisplay = countriesByRegion?.find(
      (c) => c.iso === filterCountry
    )
      ? filterCountry
      : null;
    setFilterCountry(region ? countryDisplay : "GB");
    setFilterCountries(countriesByRegion);
  };

  const countryFilterHandler = (e) => {
    setFilterCountry(e.value);
  };

  const handleProviderSelection = (e) => {
    setProvider(e.value);
    setSelectedBundles([]);
  };

  const handleProviderFilterSelection = (e) => {
    setFilterProvider(e.value);
  };

  const handleVoucherChange = (bundle, newCount) => {
    setSelectedBundles((prev) => {
      const updatedBundles = prev.map((b) =>
        b?.discountBundle === bundle?.name ? { ...b, count: newCount } : b
      );
      return updatedBundles;
    });
  };

  const vouchersInput = (bundle) => {
    const selectedBundle = selectedBundles?.find(
      (b) => b?.discountBundle === bundle?.name
    );
    const currentCount = selectedBundle ? selectedBundle?.count : 0;
    return (
      <div>
        <PR.InputText
          type="number"
          value={currentCount} // Show the current voucher count
          onChange={(e) => {
            // Parse the new value and ensure it's a number
            const newCount = parseInt(e.target.value, 10);
            if (!isNaN(newCount)) {
              handleVoucherChange(bundle, newCount);
            }
          }}
          min={0}
          placeholder="Enter voucher count"
        />
      </div>
    );
  };

  const flattenBundles = (list) => {
    return list.map((batch) => ({
      batchName: batch?.name, // Batch name
      id: batch?.bundles.map((bundles) => bundles?.bundle.id),
      redeemed: batch?.redeemed,
      total: batch?.total,
      createdAt: batch?.createdAt,
      description: batch?.bundles[0]?.bundle?.description, // Show first bundle in the collapsed row
      duration: batch?.bundles[0]?.bundle?.duration,
      dataAmount: batch?.bundles[0]?.bundle?.dataAmount,
      ourPrice: batch?.bundles[0]?.bundle?.ourPrice,
      providerName: batch?.bundles[0]?.providerName,
      // status: batch?.status,
      vouchers: batch?.bundles?.flatMap((bundle) =>
        bundle?.vouchers.map((i) => ({
          ...i,
          name: bundle?.bundle?.name,
          description: bundle?.bundle?.description,
          ourPrice: bundle?.bundle?.ourPrice,
          dataAmount: bundle?.bundle?.dataAmount,
          duration: bundle?.bundle?.duration,
        }))
      ), // Keep vouchers for expansion
      promoCode: batch?.bundles[0]?.vouchers?.[0]?.promoCode || "N/A",
    }));
  };

  const flattenedList = flattenBundles(prepaidList);

  const handleTotalVoucherChange = (e) => {
    const value = parseInt(e.target.value) || 0; // Ensure it's a number
    setTotalVouchers(value); // Update total voucher count
  };

  const copyToClipboard = (voucherCode) => {
    navigator.clipboard.writeText(voucherCode);
    toast.current.show({
      severity: 'success',
      summary: 'Copied',
      detail: `Voucher code copied to clipboard!`,
      life: 3000,
    });
  };

  const getResetHandler = () => {
    setFilters({ batchName: null, status: null });
    setGetBatchName("");
    setGetStatus("");
    setSearchValue("");
    setSearchFilter(initialFilters);
  };

  const resetData = () => {
    setSelectedRegion(null);
    setCountries(allCountries);
    setCountry("GB");
    setFilterSelectedRegion(null);
    setCreateFilters([]);
    handleFilterReset();
    setProvider([]);
    setBundles([]);
    setTotalVouchers(1);
    setDisplayBundle(false);
    setSelectedBundles([]);
    formik.resetForm({
      values: {
        batchName: "",
      },
      errors: {},
      touched: {},
    });
  };

  const handleFilterReset = () => {
    setFilterSelectedRegion(null);
    setFilterCountries(allCountries);
    setFilterCountry("GB");
    setFilterBatchName(null);
    setFilterStatus("");
    setFilterProvider("");
    setStartDate(null);
    setEndDate(null);
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setVisible(false);
    setCountries(filterCountries);
    const countryDisplay = filterCountries?.find((c) => c.iso === filterCountry)
      ? filterCountry
      : null;
    setCountry(countryDisplay);
    setSelectedRegion(filterSelectedRegion);
    const createdTimeStart =
      Array.isArray(startDate) && startDate[0]
        ? moment(startDate[0]).format("DD-MM-YYYY")
        : null;

    const createdTimeEnd =
      Array.isArray(startDate) && startDate[1]
        ? moment(startDate[1]).format("DD-MM-YYYY")
        : null;

    const redeemTimeStart =
      Array.isArray(endDate) && endDate[0]
        ? moment(endDate[0]).format("DD-MM-YYYY")
        : null;

    const redeemTimeEnd =
      Array.isArray(endDate) && endDate[1]
        ? moment(endDate[1]).format("DD-MM-YYYY")
        : null;
    const newFilters = {
      region: filterSelectedRegion,
      country: filterCountry,
      provider: filterprovider
        ? filterprovider.map((provider) => provider.name).join(",")
        : "",
      batchId: filterBatchName,
      createdTimeStart,
      createdTimeEnd,
      redeemTimeStart,
      redeemTimeEnd,
      status: filterStatus,
    };
    setFilters((prev) => ({ ...prev, ...newFilters }));
  };

  const onHideHandler = () => {
    setBundles([])
    setCreateVoucher(false)
    formik.resetForm()
    setSelectedRegion('')
    setCountry('GB')
    setProvider('')
    setTotalVouchers(1)
    setSelectedBundles([])
  }

  const optionStatusList = [
    { label: "ACTIVE", value: "ACTIVE" },
    { label: "PENDING", value: "PENDING" },
    { label: "EXPIRED", value: "EXPIRED" },
    { label: "REDEEMED", value: "USED" },
    { label: "INVALID", value: "INVALID" },
  ];

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Check if any selected bundle has a count of 0
    const hasInvalidVoucherCount = selectedBundles?.some(
      (bundle) => bundle?.count === 0
    );

    if (hasInvalidVoucherCount) {
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "Voucher count cannot be 0 in selected bundles",
      });
      return; // Prevent API call
    }
    formik.handleSubmit();
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setCurrentPage(newPage);
      const newOffset = (newPage - 1) * itemsPerPage;
      setOffset(newOffset)
    }
  };

  const handleNextPage = () => {
    const newPage = currentPage + 1;
    setCurrentPage(newPage);
    const newOffset = (newPage - 1) * itemsPerPage;
    setOffset(newOffset)
  };

  const updateBatchDisable = (rowData) => {
    setLoading(true)
    const request = {
      voucherUpdate: {
        discountType: 'prepaidbundle',
        disabled: disableText === 'Enable Batch' ? false : true
      },
      batchId: rowData
    }
    updateBatch(request, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        setDisable(false);
        setShowBatchInfoDialog(false);
        setDisableText(disableText === 'Enable Batch' ? 'Disable Batch' : 'Enable Batch')
        getBatch()
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: `${disableText === 'Disable Batch' ? "Disabled Batch successfully" : "Enabled Batch successfully"}`,
        });
      } else {
        const error = response.error || {};
        toast.current.show({
          severity: error.severity || "error",
          summary: "Error",
          detail: error.errorMsg ? error.errorMsg : error.summary || "An unknown error occurred.",
        });
      }
      setLoading(false)
    });
  }

  const renderSortableHeader = (label, field) => {
    return (
      <div onClick={() => onSortChange(field)}>
        {label}
        <i
          className={`pi pi-sort-${sortField === field ? (sortOrder === 1 ? 'amount-up-alt' : 'amount-down') : 'alt'}`}
          style={{ marginLeft: '0.5rem' }}
        />
      </div>
    );
  };

  const downloadXls = (batch) => {
    const mappedBatch = {
      batchName: batch?.name,
      vouchers: batch.bundles.flatMap(allBundle =>
        allBundle.vouchers.map(voucher => ({
          description: allBundle?.bundle?.description,
          dataAmount: allBundle?.bundle?.dataAmount,
          duration: allBundle?.bundle?.duration,
          ourPrice: allBundle?.bundle?.ourPrice,
          vouchersnapshot: {
            promoCode: voucher?.vouchersnapshot?.promoCode,
            status: voucher?.vouchersnapshot?.status
          }
        }))
      )
    };

    handleExportCSV(mappedBatch);
  };

  const formik = useFormik({
    initialValues: {
      batchName: "",
    },
    validationSchema: Yup.object({
      batchName: Yup.string()
        .required("Batch name is required")
        .min(3, "Batch name must be at least 3 characters")
        .max(50, "Batch name cannot exceed 50 characters")
    }),
    onSubmit: handleSubmit,
  });

  return (
    <>
      <div className="main">
        <div className="layout-sidebar">
          <AdminHeader />
        </div>
        <div className="layout-content-wrapper">
          <section className="admin-users-section promo-code-section">
            <div className="grid grid-nogutter">
              <div className="col-12">
                <div className="heading-sec">
                  <div className="flex align-items-center justify-content-between mb-4">
                    <h1>Prepaid/Retail Vouchers</h1>
                    <div className="right-section flex align-items-center gap-3">
                      <PR.Button
                        label="Create Prepaid Voucher"
                        className="export-button"
                        icon="pi pi-plus"
                        iconPos="left"
                        onClick={() => setCreateVoucher(true)}
                        disabled={loading}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex align-items-center justify-content-between filter-right">
                  <div className="flex align-items-center gap-2">
                    <span className="p-input-icon-right search-field">
                      <i className="pi pi-search" />
                      <PR.InputText
                        name="search"
                        placeholder="Search"
                        value={searchValue}
                        onChange={globalSearchHandler}
                        disabled={loading}
                      />
                    </span>

                    <PR.Dropdown
                      filter
                      placeholder="Batch Name"
                      value={getBatchName}
                      options={batchOptions}
                      resetFilterOnHide
                      optionLabel="name"
                      optionValue="value"
                      name="batchName"
                      maxSelectedLabels={1}
                      onChange={(e) => setGetBatchName(e.value)}
                      disabled={loading}
                    />

                    <PR.MultiSelect
                      placeholder="Status"
                      value={getStatus}
                      options={optionStatusList}
                      optionLabel="label"
                      optionValue="value"
                      name="status"
                      maxSelectedLabels={1}
                      onChange={(e) => setGetStatus(e.value)}
                      disabled={loading}
                    />
                    <PR.Button
                      type="submit"
                      label="Search"
                      className="searchBtn"
                      onClick={getSearchHandler}
                      disabled={loading}
                    />
                    <PR.Button
                      type="reset"
                      label="Reset"
                      className="resetBtn"
                      onClick={getResetHandler}
                      disabled={loading}
                    />
                  </div>
                  <PR.Button
                    label="Filter"
                    className="exportBtn"
                    icon="pi pi-filter"
                    onClick={() => setVisible(true)}
                    disabled={loading}
                  />
                </div>
              </div>
            </div>
            <div className="prepaid-table card">
              <PR.DataTable
                loading={loading}
                value={flattenedList}
                stripedRows
                className="vouchers-table"
                expandedRows={expandedRows}
                sortField={sortField}
                sortOrder={sortOrder}
                onSort={onSortChange}
                filters={searchFilter}
                globalFilterFields={["batchName", "promoCode"]}
                onRowToggle={(e) => {
                  // Filter out rows where voucher is disabled
                  const validExpandedRows = e.data.filter(
                    (rowData) => !rowData.vouchers[0].vouchersnapshot.disabled
                  );
                  setExpandedRows(validExpandedRows);
                }}
                rowExpansionTemplate={rowExpansionTemplate}
                rowClassName={(rowData) => {
                  let className = "";
                  if (rowData.vouchers[0].vouchersnapshot.disabled === true) {
                    className += "disabled-row ";
                  }
                  return className.trim();
                }}

                emptyMessage={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px', color: '#888', fontSize: '1.2rem', padding: '2rem', }} >
                  <p>No results found</p>
                </div>
                }
              >
                <PR.Column expander style={{ width: "3%" }} />
                <PR.Column
                  field="batchName"
                  body={batchName}
                  header={renderSortableHeader('Batch Name', 'batchName')}
                  style={{ width: "23%" }}
                ></PR.Column>
                <PR.Column
                  header={renderSortableHeader('Plan', 'description')}
                  body={(rowData) => {
                    const isRowExpanded = expandedRows?.some((row) => row?.batchName === rowData?.batchName); // Check if the row is expanded
                    if (!rowData.vouchers[0].vouchersnapshot.disabled) {
                      return (
                        <p className="batch-p">
                          <strong>{`${rowData.redeemed}/${rowData.total} Vouchers redeemed`}</strong>
                          { !isRowExpanded && ' (Click to expand the detail view of the batch)'}
                        </p>
                      );
                      
                    } else {
                      return (
                        <span className="batch-p">
                          Batch disabled -{" "}
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setDisable(true)
                              setDisableText('Enable Batch')
                              setBatchData(rowData)
                            }}
                            style={{ color: "black", textDecoration: "underline", cursor: "pointer", border: "none" }}
                          >
                            Click here
                          </button>{" "}
                          to enable the batch again
                        </span>
                      );
                    }
                  }}
                  style={{ width: "22%" }}
                />
                <PR.Column
                  header={renderSortableHeader('Duration', 'duration')}
                  style={{ width: "8%" }}
                ></PR.Column>
                <PR.Column
                  header={renderSortableHeader('Data', 'dataAmount')}
                  style={{ width: "8%" }}
                ></PR.Column>
                <PR.Column
                  header={renderSortableHeader('Price', 'ourPrice')}
                  style={{ width: "7%" }}
                ></PR.Column>
                <PR.Column
                  header={renderSortableHeader('Status', 'status')}
                  style={{ width: "8%" }}
                ></PR.Column>
                <PR.Column                
                  header={renderSortableHeader("Voucher Code", "promoCode")}
                  className="voucherCode"
                  style={{ width: "17%" }}
                ></PR.Column>
                <PR.Column
                  className="last-column"
                style={{ width: "2%" }}
                ></PR.Column>
              </PR.DataTable>
              {!loading &&
                <div className="pagination">
                  <PR.Button onClick={handlePrevPage} disabled={currentPage === 1}>Prev</PR.Button>
                  <span className="active">{currentPage}</span>
                  <PR.Button onClick={handleNextPage} type="button" disabled={!totalRecords} >Next</PR.Button>
                </div>
              }
              <PR.Dialog
                visible={disable}
                dismissableMask={true}
                draggable={false}
                resizable={false}
                header={disableText === 'Disable Batch' ? 'Disable Batch' : 'Enable Batch'}
                onHide={() => setDisable(false)}
                className="affiliate-dialog"
                maskClassName="affiliate-dialog-mask"
              >
                <p>Are you sure you want to {disableText === 'Disable Batch' ? 'Disable' : 'Enable'} this batch?</p>
                <div className="dialog-footer buttons-sections flex gap-3 align-items-center justify-content-end">
                  <PR.Button onClick={() => setDisable(false)} label="No" className="confirm-button  reset-btn min-width" />
                  <PR.Button onClick={() => { if (batchData) { updateBatchDisable(batchData?.batchName) } }} label="Yes" className="confirm-button min-width" disabled={loading} />
                </div>
              </PR.Dialog>
            </div>
          </section>
        </div>
        <div className="layout-content-wrapper">
          <AdminFooter />
        </div>

        {/* Create Prepaid Vouchers Dialog */}
        <PR.Dialog
          header="Create Prepaid Voucher(s)"
          visible={createVoucher}
          draggable={false}
          resizable={false}
          onHide={onHideHandler}
          dismissableMask={true}
          maskClassName="create-voucher-dialog-mask"
          className="filter-dialog voucher-modal create-voucher-dialog"
        >
          <form className="filter-content">
            <div className="grid mt-2">
              <div className="col-12 md:col-6">
                <p className="mb-2">Batch Name</p>
                <PR.InputText
                  value={formik.values.batchName}
                  keyfilter={/^[a-zA-Z0-9-_ ]*$/}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Name of the batch"
                  className="w-full"
                  name="batchName"
                  disabled={catalogLoading}
                />
                {formik.touched.batchName && formik.errors.batchName && (
                  <small className="p-error">{formik.errors.batchName}</small>
                )}
              </div>
              <div className="col-12 md:col-6">
                <p className="mb-2">Voucher Count</p>
                <PR.InputText
                  type="number"
                  value={totalVouchers}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value <= 9999) {
                      handleTotalVoucherChange(e);
                    }
                  }}
                  placeholder="Total Number of Vouchers"
                  min={1}
                  maxLength={4}
                  disabled={catalogLoading}
                  className="w-12"
                />
              </div>
            </div>
            <div className="grid mt-2">
              <div className="col-12 pb-1">
                <p>Select region & country</p>
              </div>
              <div className="col-12">
                <div className="flex gap-2 w-full align-items-center">
                  <PR.Dropdown
                    value={selectedRegion}
                    onChange={(e) => handleRegionSelection(e.value)}
                    options={uniqueRegions}
                    optionLabel="label"
                    placeholder="Select region"
                    filter
                    className="w-full"
                    disabled={catalogLoading}
                  />
                  <PR.Dropdown
                    value={country}
                    onChange={handleCountrySelection}
                    options={countries}
                    optionLabel="name"
                    optionValue="iso"
                    placeholder="Select country"
                    filter
                    resetFilterOnHide
                    className="w-full"
                    disabled={catalogLoading}
                  />
                  <PR.MultiSelect
                    value={provider}
                    onChange={handleProviderSelection}
                    options={providerList}
                    optionLabel="name"
                    placeholder="Select provider"
                    className="w-full"
                    disabled={catalogLoading}
                  />
                  <PR.Button
                    label="Search"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      searchHandler();
                    }}
                    className="search-button"
                    disabled={
                      (!provider.length && !selectedRegion && !country) ||
                      catalogLoading
                    }
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="grid mt-2">
                <div className="col-12 pb-1">
                  <p>Select bundle(s)</p>
                </div>
                <div className="col-12">
                  <PR.DataTable
                    loading={catalogLoading}
                    value={bundles}
                    className="bundles-table custom-min-height"
                  >
                    {bundles.length === 0 && (
                      <PR.Column colSpan={1} style={{ textAlign: "center" }}>
                        No records found.
                      </PR.Column>
                    )}
                    <PR.Column body={selectBox} header="Select"></PR.Column>
                    <PR.Column
                      field="description"
                      header="Name of the bundle"
                      body={(rowData) => (rowData?.description ? `${filterCountryName(rowData?.description)}` : "-")}
                    ></PR.Column>
                    <PR.Column
                      field="dataAmountForDisplay"
                      header="Data"
                    ></PR.Column>
                    <PR.Column
                      field="duration"
                      header="Duration"
                      body={(rowData) => `${rowData?.duration} days`}
                    ></PR.Column>
                    <PR.Column
                      field="providerName"
                      header="Provider"
                    ></PR.Column>
                    {/* <PR.Column field="roaming" header="Roaming"></PR.Column> */}
                    <PR.Column
                      field="ourPrice"
                      header="Price"
                      body={(rowData) => (
                        rowData?.ourPrice ? `${globalConfig.defaultCurrency} ${rowData?.ourPrice}` : '-'
                      )}
                    ></PR.Column>
                    <PR.Column
                      body={vouchersInput}
                      header="No. of vouchers"
                      className="last-column"
                    ></PR.Column>
                  </PR.DataTable>
                </div>
              </div>
              <div className="buttons-sections flex align-items-center justify-content-between gap-3 mt-3">
                <p></p>
                <div className="flex align-items-center gap-3">
                  <PR.Button
                    label="Reset"
                    type="button"
                    className="confirm-button reset-btn min-width"
                    onClick={resetData}
                  />
                  <PR.Button
                    label="Create"
                    type="submit"
                    className="confirm-button min-width"
                    disabled={!displayBundle || selectedBundles?.length === 0}
                    onClick={handleFormSubmit}
                  />
                </div>
              </div>
            </div>
          </form>
        </PR.Dialog>

        {/* Filter Options Dialog */}
        <PR.Dialog
          header="Filter"
          visible={visible}
          draggable={false}
          resizable={false}
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
            margin: "0",
          }}
          onHide={() => setVisible(false)}
          dismissableMask={true}
          className="filter-dialog voucher-modal"
        >
          <form
            autoComplete="off"
            className="mt-0"
            onSubmit={handleFilterSubmit}
          >
            <div className="filter-content">
              <div className="grid">
                <div className="col-12 pb-1">
                  <p>By region or country or provider</p>
                </div>
                <div className="col-12 md:col-4">
                  <PR.Dropdown
                    value={filterSelectedRegion}
                    onChange={(e) => regionFilterHandler(e.value)}
                    options={uniqueRegions}
                    optionLabel="label"
                    placeholder="Select region"
                    filter
                    resetFilterOnHide
                    showClear="true"
                    className="w-full"
                  />
                </div>
                <div className="col-12 md:col-4">
                  <PR.Dropdown
                    value={filterCountry}
                    onChange={countryFilterHandler}
                    options={filterCountries}
                    optionLabel="name"
                    optionValue="iso"
                    placeholder="Select country"
                    showClear="true"
                    filter
                    resetFilterOnHide
                    className="w-full"
                  />
                </div>
                <div className="col-12 md:col-4">
                  <PR.MultiSelect
                    value={filterprovider}
                    onChange={handleProviderFilterSelection}
                    options={providerList}
                    optionLabel="name"
                    placeholder="Select provider"
                    className="w-full"
                  />
                </div>
              </div>
              <div className="grid mt-2">
                <div className="col-12 pb-1">
                  <p>By batch name or created date</p>
                </div>
                <div className="col-12 md:col-6">
                  <PR.Dropdown
                    value={filterBatchName}
                    onChange={(e) => { setFilterBatchName(e.value) }}
                    options={batchOptions}
                    showClear={true}
                    resetFilterOnHide
                    optionLabel="name"
                    placeholder="Select batch"
                    filter
                    className="w-full"
                  />
                </div>
                <div className="col-12 md:col-6">
                  <PR.Calendar
                    value={startDate}
                    selectionMode="range"
                    onChange={(e) => setStartDate(e.value)}
                    dateFormat="dd/mm/yy"

                    showIcon
                    placeholder="Select date"
                  />
                </div>
              </div>
              <div className="grid mt-2">
                <div className="col-12 pb-1">
                  <p>Voucher status or date redeemed</p>
                </div>
                <div className="col-12 md:col-6">
                  <PR.MultiSelect
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e.value)}
                    options={optionStatusList}
                    optionLabel="label"
                    placeholder="Select status"
                    filter
                    className="w-full"
                  />
                </div>
                <div className="col-12 md:col-6">
                  <PR.Calendar
                    value={endDate}
                    selectionMode="range"
                    onChange={(e) => setEndDate(e.value)}
                    dateFormat="dd/mm/yy"
                    showIcon
                    placeholder="Select date"
                  />
                </div>
              </div>
              <div className="buttons-sections flex align-items-center justify-content-end gap-3">
                <PR.Button
                  label="Reset"
                  type="button"
                  className="confirm-button reset-btn min-width"
                  onClick={handleFilterReset}
                />
                <PR.Button
                  label="Apply"
                  type="submit"
                  className="confirm-button min-width"
                />
              </div>
            </div>
          </form>
        </PR.Dialog>

        {/* Prepaid Vouchers Info Dialog */}
        <PR.Dialog
          header="Prepaid/Retail Voucher Details"
          visible={showBatchInfoDialog}
          draggable={false}
          resizable={false}
          onHide={() => setShowBatchInfoDialog(false)}
          dismissableMask={true}
          maskClassName="create-voucher-dialog-mask"
          className="filter-dialog voucher-modal create-voucher-dialog"
        >
          {selectedInfoBatch && selectedInfoBatch.length > 0 && (
            <div>
              {selectedInfoBatch.map(
                (
                  batch,
                  index // Iterate through each batch
                ) => (
                  <div key={index}>
                    <div className="grid">
                      <div className="col-12 md:col-6">
                        <p className="mb-2">Batch Name</p>
                        <PR.InputText
                          value={batch.name} // Accessing batch name
                          className="w-full"
                          name="batchName"
                          readOnly
                        />
                      </div>
                      <div className="col-12 md:col-6">
                        <p className="mb-2">Voucher Count</p>
                        <PR.InputText
                          value={batch.total} // Accessing total voucher count
                          className="w-full"
                          type="number"
                          name="voucherCount"
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="grid mt-2">
                      <div className="col-12 pb-1">
                        <p className="m-0">Bundle(s) Details</p>
                      </div>
                      <div className="col-12">
                        <PR.DataTable
                          value={batch.bundles} // Directly using the bundles array
                          className="bundles-table"
                        >
                          <PR.Column
                            field="bundle.description" // Accessing the name of the bundle
                            header="Name of the bundle"
                            body={(rowData) => (rowData?.bundle.description ?
                              `${filterCountryName(rowData?.bundle.description)}` : '-'
                            )}
                          />
                          <PR.Column
                            field="bundle.dataAmount" // Accessing data amount for display
                            header="Data"
                            body={(rowData) => setDataAmount(rowData?.bundle)}
                          />
                          <PR.Column
                            field="bundle.duration" // Accessing duration
                            header="Duration"
                            body={(rowData) => (rowData?.bundle.duration ?
                              `${rowData?.bundle.duration} days` : '-'
                            )}
                          />
                          <PR.Column
                            field="providerName" // Accessing provider name
                            header="Provider"
                          />
                          <PR.Column
                            field="bundle.roamingEnabled" // Accessing roaming information
                            header="Roaming"
                            body={(data) => <RoamingColumn data={data} />}
                          />
                          <PR.Column
                            field="bundle.price" // Accessing price
                            header="Price"
                            body={(rowData) => (
                              rowData?.bundle.ourPrice ?
                                `${globalConfig.defaultCurrency}
                                ${rowData?.bundle.ourPrice}` : '-'
                            )}
                          />
                          <PR.Column
                            field="vouchers" // Accessing vouchers for the count
                            body={(data) => data.vouchers.length} // Displaying the number of vouchers
                            header="No. of vouchers"
                            className="last-column"
                          />
                        </PR.DataTable>
                      </div>
                      <PR.Dialog
                        visible={disable}
                        dismissableMask={true}
                        draggable={false}
                        resizable={false}
                        header={disableText === 'Disable Batch' ? 'Disable Batch' : 'Enable Batch'}
                        onHide={() => setDisable(false)}
                        className="affiliate-dialog"
                        maskClassName="affiliate-dialog-mask"
                      >
                        <p>Are you sure you want to {disableText === 'Disable Batch' ? 'Disable' : 'Enable'} this batch?</p>
                        <div className="dialog-footer buttons-sections flex gap-3 align-items-center justify-content-end">
                          <PR.Button onClick={() => setDisable(false)} label="No" className="confirm-button reset-btn min-width" />
                          <PR.Button onClick={() => updateBatchDisable(batch?.name)} label="Yes" className="confirm-button min-width" disabled={loading} />
                        </div>
                      </PR.Dialog>
                    </div>

                    <div className="buttons-sections flex align-items-center justify-content-between gap-3 mt-3">
                      <div>
                        Created date: {" "}
                        {moment.utc(batch.createdAt).local().format("DD-MM-YY | HH:mm:ss")}
                      </div>

                      <div className="flex align-items-center gap-3">
                        <PR.Button
                          label="Download CSV"
                          type="button"
                          className="confirm-button reset-btn1 min-width"
                          onClick={() => downloadXls(batch)}
                          disabled={disableText === 'Enable Batch'}
                        />
                        <PR.Button
                          // label={batchUpdateButton(batch.bundles.map(item => item.vouchers))}
                          label={disableText}
                          type="button"
                          className="confirm-button batch-btn min-width"
                          onClick={() => setDisable(true)}
                        />
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </PR.Dialog>

        <PR.Toast ref={toast} position="top-right" />
      </div>
    </>
  );
};

export default PrepaidRetailVouchers;
