import React from 'react';

const ImagePreview = ({ formikError, defaultImage, newImage }) => {
    return (!formikError && newImage) ?
    (
        <div className="image-preview">
            <img src={URL.createObjectURL(newImage)} alt="img" className='logo_icon' />
        </div>
    )
    :
    (defaultImage) &&
    
    <div className="image-preview">
        <img src={"data:image/png;base64," + defaultImage} className="logo_icon" alt="logo" />
    </div>
}

export default ImagePreview;