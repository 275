import React, { memo } from "react";
import * as PR from "../../../prime-modules/index";
import ChartDataLabels from "chartjs-plugin-datalabels";
import countries from "i18n-iso-countries";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const BundlesSoldBarGraph = ({ data }) => {
  const sortedData = data ? data.slice().sort((a, b) => b.yValue - a.yValue) : [];
  const labels = sortedData.map((value) => value.xValue);
  const values = sortedData.map((value) => value.yValue);
  const barThicknessValue = sortedData.length <= 4 ? 30 : 'flex';
  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "right",
        itemWidth: 150,
        labels: {
          color: "#495057",
          usePointStyle: true,
        },
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const isoCode = context.label;
            const countryName = countries.getName(isoCode, "en") || isoCode;
            return `${countryName}: ${context.raw}`;
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          stepSize:5
          }
      },
    },
    categorySpacing: 30,
  };

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: "#003366",
        maxBarThickness: 50, // Maximum width for bars
        barThickness: barThicknessValue,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  return (
    <PR.Chart
      type="bar"
      data={chartData}
      plugins={[ChartDataLabels]}
      options={barChartOptions}
    />
  );
};

export default memo(BundlesSoldBarGraph);
