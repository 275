import { ChromePicker } from "react-color";
import * as PR from "../../../prime-modules/index";
import { useRef } from "react";

const ColorPickerComponent = ({ label, color, onChange }) => {
    const overlayRef = useRef(null);

    const handleColorChange = (newColor) => {
        onChange(newColor.hex);
    };

    return (
        <div className="colorPickersColumn">
            <h4>{label}</h4>
            <PR.Button type="button" style={{ backgroundColor: color, borderColor: color }} onClick={(event) => overlayRef.current.toggle(event)} />
            <PR.OverlayPanel ref={overlayRef} appendTo={document.body} showCloseIcon dismissable>
                <ChromePicker color={color} onChangeComplete={handleColorChange} disableAlpha={true}/>
            </PR.OverlayPanel>
            <h4>({color})</h4>
        </div>
    );
}

export default ColorPickerComponent;