import React, { useState, useMemo } from 'react';
import * as PR from "../../../prime-modules/index";
import "./Home.scss";
import { useFormik } from 'formik';
import { uploadAssetsApi } from "../../../services/api"
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { trimFormData, validateTextField } from '../../../utils';
import { getAllAssets } from '../../../genericFunctions/getAssets';
import ImagePreview from '../generic/imagePreview';
import { uploadWidthHeightImage } from '../../../utils/reuse';
// import { isValid } from 'i18n-iso-countries';

const Home = ({assets, disableSave}) => {
    const dispatch = useDispatch();
    const toast = React.useRef(null)
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);

    const [selectedMainImage, setSelectedMainImage] = useState(null);
    const [instantActivation, setInstantActivation] = useState(null);
    const [attractiveRates, setAttractiveRates] = useState(null);
    const [globalCoverage, setGlobalCoverage] = useState(null);
    const [unlockWorldImage, setUnlockWorldImage] = useState(null);
    const [benifitRightImage, setBenifitRightImage] = useState(null);
    const [benifitIcon1, setBenifitIcon1] = useState(null);
    const [benifitIcon2, setBenifitIcon2] = useState(null);
    const [benifitIcon3, setBenifitIcon3] = useState(null);
    const [benifitIcon4, setBenifitIcon4] = useState(null);
    const [benifitIcon5, setBenifitIcon5] = useState(null);
    const [partnerRightImage, setPartnerRightImage] = useState(null)


    const validationSchema = Yup.object().shape({
        HOME0002: validateTextField(20, 100, 'Top Header'),
        HOME0003: validateTextField(20, 250, 'Top Header Description'),
        HOME0004: validateTextField(5, 30, 'Button'),
        HOME0005: validateTextField(10, 30, 'Panel1 Header'),
        HOME0006: validateTextField(20, 200, 'Panel1 Description'),
        HOME0008: validateTextField(10, 30, 'Panel2 Header'),
        HOME0009: validateTextField(20, 200, 'Panel2 Description'),
        HOME0011: validateTextField(10, 30, 'Panel3 Header'),
        HOME0012: validateTextField(20, 200, 'Panel3 Description'),
        HOME0015: validateTextField(20, 50, 'Unlock World Header'),
        HOME0016: validateTextField(20, 200, 'Unlock World Description'),
        HOME0017: validateTextField(5, 30, 'Unlock World Button'),
        HOME0018: validateTextField(10, 30, 'Benefit Header 1'),
        HOME0019: validateTextField(20, 200, 'Benefit Description 1'),
        HOME0021: validateTextField(10, 30, 'Benefit Header 2'),
        HOME0022: validateTextField(20, 200, 'Benefit Description 2'),
        HOME0024: validateTextField(10, 30, 'Benefit Header 3'),
        HOME0025: validateTextField(20, 200, 'Benefit Description 3'),
        HOME0027: validateTextField(10, 30, 'Benefit Header 4'),
        HOME0028: validateTextField(20, 200, 'Benefit Description 4'),
        HOME0030: validateTextField(10, 30, 'Benefit Header 5'),
        HOME0031: validateTextField(20, 200, 'Benefit Description 5'),
        HOME0034: validateTextField(10, 30, 'Partner Header'),
        HOME0035: validateTextField(20, 200, 'Partner Description'),
        HOME0036: validateTextField(5, 30, 'Button'),
    });

    const handleSubmit = async (data) => {
        const formData = new FormData();
        const trimmedFormData = trimFormData(data);
        formData.append('screen', 'home');
        for (const field in trimmedFormData) {
            if (trimmedFormData.hasOwnProperty(field)) {
                const value = trimmedFormData[field];
                if (value && typeof value === 'string' && !(value.startsWith('iVBORw0KGgoAAAANSUhEUg') || value.startsWith('/9j/4AAQSkZJRgA'))) {
                    formData.append(field, value);
                }
            } 
        }
        const getCMSResponse = (response) => {
            if (response.result === 'SUCCESS') {
                if (response.data === 'success') {
                    if (toast.current)
                        toast.current.show({ severity: 'success', summary: 'Success', detail: ' Updated successfully' });
                }
                getAllAssets(toast, dispatch, headers);
            } else if (response.result === "FAILED" && response.error) {
                const error = response.error;
                toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
        };
        uploadAssetsApi(formData, headers, dispatch, getCMSResponse);
    }

     const formik = useFormik({
        initialValues: assets,
        validationSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true, 
    });

    return (
        <div className='home'>
            <div className="card">
                    <div className="imageFormat mb-4 ">
                        <strong>(Image Size Should not exceed 1MB)</strong>
                    </div>
                {/* <h2>Home Screen Settings</h2> */}
                <form onSubmit={formik.handleSubmit}>
                    {/* Main Background Image */}
                    <div className="grid grid-nogutter align-items-center mb-4">
                        <div className='col-3'>
                            <label htmlFor="HOME0001" className="cmsLabels">Main Background Image <span className='imageFormat'>(png, jpeg, 1920W X 949H)</span></label>
                        </div>
                        <div className="col-4">
                            <div className="file-upload-section">
                                <PR.InputText
                                    type="file"
                                    name="HOME0001"
                                    className="file-upload-input"
                                    accept="image/jpeg,image/png"
                                    onChange={async(event) => {
                                        const file = event.currentTarget.files[0];
                                        setSelectedMainImage(file);
                                        formik.handleChange(event); 
                                        uploadWidthHeightImage(file, "HOME0001", toast, dispatch, headers, 'home')
                                    }}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            {selectedMainImage && formik.touched.HOME0001 && formik.errors.HOME0001 ? (
                                <div className='error-msg'>{formik.errors.HOME0001}</div>
                            ) : null}
                        </div>
                        <div className="col-12">
                            <div className="grid grid-nogutter">
                                <div className="col-12">
                                    <div className="label-input-wrapper">
                                        <div className="col-3 invisible-label">
                                            {selectedMainImage && (<p>Main Background Image :</p>)}</div>
                                        <div>
                                            <ImagePreview formikError={formik.errors.HOME0001} defaultImage={assets.HOME0001} newImage={selectedMainImage}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Top Header */}
                    <div className="grid grid-nogutter align-items-center mb-4">
                        <div className='col-3'>
                            <label htmlFor="HOME0002" className="cmsLabels">Top Header</label>
                        </div>
                        <div className="col-4">
                            <div className="card file-upload-section">
                                <PR.InputText
                                    id="HOME0002"
                                    name="HOME0002"
                                    aria-describedby="HOME0002"
                                    value={formik.values.HOME0002}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            {formik.touched.HOME0002 && formik.errors.HOME0002 ? (
                                <div className='error-msg'>{formik.errors.HOME0002}</div>
                            ) : null}
                        </div>
                    </div>

                    {/* Top Header Description */}
                    <div className="grid grid-nogutter align-items-center mb-4">
                        <div className='col-3'>
                            <label htmlFor="HOME0003" className="cmsLabels">Top Header Description</label>
                        </div>
                        <div className="col-4">
                            <div className="card file-upload-section">
                                <PR.InputTextarea autoResize id="HOME0003"
                                    name="HOME0003"
                                    aria-describedby="HOME0003"
                                    value={formik.values.HOME0003}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                            </div>
                            {formik.touched.HOME0003 && formik.errors.HOME0003 ? (
                                <div className='error-msg'>{formik.errors.HOME0003}</div>
                            ) : null}
                        </div>
                    </div>

                    {/* Button Name */}
                    <div className="grid grid-nogutter align-items-center mb-4">
                        <div className='col-3'>
                            <label htmlFor="HOME0004" className="cmsLabels">Button Name</label>
                        </div>
                        <div className="col-4">
                            <div className="card file-upload-section">
                                <PR.InputText
                                    id="HOME0004"
                                    name="HOME0004"
                                    aria-describedby="HOME0004"
                                    value={formik.values.HOME0004}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            {formik.touched.HOME0004 && formik.errors.HOME0004 ? (
                                <div className='error-msg'>{formik.errors.HOME0004}</div>
                            ) : null}
                        </div>
                    </div>

                    <PR.Divider />

                    <div className="grid grid-nogutter">
                        <div className="col-12">
                            <label htmlFor="panelsSection"><h3 className="mb-3 mt-1">Panels Section</h3></label>
                            <div className="card">
                                <div className='grid'>
                                    <div className="col-2"></div>
                                    <div className="col">
                                        <div className="col-12 text-center">
                                            <h4 className="mb-2 mt-0">Panel-1</h4>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="col-12 text-center">
                                            <h4 className="mb-2 mt-0">Panel-2</h4>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="col-12 text-center">
                                            <h4 className="mb-2 mt-0">Panel-3</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="label-input-wrapper align-items-center">
                                    <div className="col-2">
                                        <div className="col-12 mb-4">
                                            <label htmlFor="panelHeader" className="cmsLabels">Header</label>
                                        </div>
                                        <div className="col-12 mb-4">
                                            <label htmlFor="panelDescription" className="cmsLabels">Description</label>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="panel1Image" className="cmsLabels">Image <span className='imageFormat'>(png, jpeg, 53W X 70H)</span></label>
                                        </div>
                                    </div>
                                    {/* Panel-1 Section */}
                                    <div className="col">
                                        <div className="panel-card">
                                            {/* Panel-1 Header */}
                                            <div className="grid grid-nogutter align-items-center mb-3">
                                                <div className="col-12">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            id="HOME0005"
                                                            name="HOME0005"
                                                            aria-describedby="HOME0005"
                                                            value={formik.values.HOME0005}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.HOME0005 && formik.errors.HOME0005 ? (
                                                        <div className='error-msg'>{formik.errors.HOME0005}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {/* Panel-1 Description */}
                                            <div className="grid grid-nogutter align-items-center mb-3">
                                                <div className="col-12">
                                                    <div className="file-upload-section">
                                                        <PR.InputTextarea autoResize 
                                                         id="HOME0006"
                                                         name="HOME0006"
                                                         aria-describedby="HOME0006"
                                                         value={formik.values.HOME0006}
                                                         onChange={formik.handleChange}
                                                         onBlur={formik.handleBlur} />
                                                    </div>
                                                    {formik.touched.HOME0006 && formik.errors.HOME0006 ? (
                                                        <div className='error-msg'>{formik.errors.HOME0006}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {/* Panel-1 Image */}
                                            <div className="grid grid-nogutter align-items-center">
                                                <div className="col-12">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            type="file"
                                                            name="HOME0007"
                                                            className="file-upload-input"
                                                            accept="image/png, image/jpeg"
                                                            onChange={async(event) => {                                                                              
                                                            const file = event.currentTarget.files[0];
                                                            setInstantActivation(file);
                                                            formik.handleChange(event);
                                                            uploadWidthHeightImage(file, "HOME0007", toast, dispatch, headers, 'home')
                                                            }}
                                                           onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {instantActivation && formik.touched.HOME0007 && formik.errors.HOME0007 ? (
                                                        <div className='error-msg'>{formik.errors.HOME0007}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Panel-2 Section */}
                                    <div className="col">
                                        <div className="panel-card">
                                            {/* Panel-2 Header */}
                                            <div className="grid grid-nogutter align-items-center mb-3">
                                                <div className="col-12">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            id="HOME0008"
                                                            name="HOME0008"
                                                            aria-describedby="HOME0008"
                                                            value={formik.values.HOME0008}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.HOME0008 && formik.errors.HOME0008 ? (
                                                        <div className='error-msg'>{formik.errors.HOME0008r}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {/* Panel-2 Description */}
                                            <div className="grid grid-nogutter align-items-center mb-3">
                                                <div className="col-12">
                                                    <div className="file-upload-section">
                                                        <PR.InputTextarea autoResize
                                                            id="HOME0009"
                                                            name="HOME0009"
                                                            aria-describedby="HOME0009"
                                                            value={formik.values.HOME0009}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur} />
                                                    </div>
                                                    {formik.touched.HOME0009 && formik.errors.HOME0009 ? (
                                                        <div className='error-msg'>{formik.errors.HOME0009}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {/* Panel-2 Image */}
                                            <div className="grid grid-nogutter align-items-center">
                                                <div className="col-12">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            type="file"
                                                            name="HOME0010"
                                                            className="file-upload-input"
                                                            accept="image/png, image/jpeg"
                                                            onChange={async(event) => {
                                                                const file = event.currentTarget.files[0];
                                                                setAttractiveRates(file);
                                                                formik.handleChange(event);
                                                                uploadWidthHeightImage(file, "HOME0010", toast, dispatch, headers, 'home')
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {attractiveRates && formik.touched.HOME0010 && formik.errors.HOME0010 ? (
                                                        <div className='error-msg'>{formik.errors.HOME0010}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Panel-3  */}
                                    <div className="col">
                                        <div className="panel-card">
                                            <div className="grid grid-nogutter align-items-center mb-3">
                                                <div className="col-12">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            id="HOME0011"
                                                            name="HOME0011"
                                                            aria-describedby="HOME0011"
                                                            value={formik.values.HOME0011}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.HOME0011 && formik.errors.HOME0011 ? (
                                                        <div className='error-msg'>{formik.errors.HOME0011}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {/* Panel-3 Description */}
                                            <div className="grid grid-nogutter align-items-center mb-3">
                                                <div className="col-12">
                                                    <div className="file-upload-section">
                                                        <PR.InputTextarea autoResize
                                                            id="HOME0012"
                                                            name="HOME0012"
                                                            aria-describedby="HOME0012"
                                                            value={formik.values.HOME0012}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur} />
                                                    </div>
                                                    {formik.touched.HOME0012 && formik.errors.HOME0012 ? (
                                                        <div className='error-msg'>{formik.errors.HOME0012}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {/* Panel-3 Image */}
                                            <div className="grid grid-nogutter align-items-center">
                                                <div className="col-12">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            type="file"
                                                            name="HOME0013"
                                                            className="file-upload-input"
                                                            accept="image/png, image/jpeg"
                                                            onChange={async(event) => {
                                                                const file = event.currentTarget.files[0];
                                                                setGlobalCoverage(file);
                                                                formik.handleChange(event);
                                                                uploadWidthHeightImage(file, "HOME0013", toast, dispatch, headers, 'home')
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {globalCoverage && formik.touched.HOME0013 && formik.errors.HOME0013 ? (
                                                        <div className='error-msg'>{formik.errors.HOME0013}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="label-input-wrapper">
                                    <div className="col-2"></div>
                                    <div className="col">
                                        <div className="grid grid-nogutter">
                                            <div className="col-12">
                                                <ImagePreview formikError={formik.errors.HOME0007} defaultImage={assets.HOME0007} newImage={instantActivation}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="grid grid-nogutter">
                                            <div className="col-12">
                                                <ImagePreview formikError={formik.errors.HOME0010} defaultImage={assets.HOME0010} newImage={attractiveRates}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="grid grid-nogutter">
                                            <div className="col-12">
                                                <ImagePreview formikError={formik.errors.HOME0013} defaultImage={assets.HOME0013} newImage={globalCoverage}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <PR.Divider />

                    <div className="grid grid-nogutter">
                        <div className="col-12">
                            <label htmlFor="unlockSection"><h3 className="mb-3 mt-1">Unlock World Section</h3></label>
                            <div className="card">
                                <div className="grid">
                                    <div className="col-6">
                                        {/* Seamless Connectivity Phone Image */}
                                        <div className="grid grid-nogutter align-items-center mb-4">
                                            <div className='col-5'>
                                                <label htmlFor="HOME0014" className="cmsLabels">Seamless Connectivity Phone Image <br />
                                                    <span className='imageFormat'>(png, jpeg, 432W X 572H)</span></label>
                                            </div>
                                            <div className="col-7">
                                                <div className="file-upload-section">
                                                    <PR.InputText
                                                        type="file"
                                                        name="HOME0014"
                                                        className="file-upload-input"
                                                        accept="image/png, image/jpeg"
                                                        onChange={async(event) => {
                                                            const file = event.currentTarget.files[0];
                                                            setUnlockWorldImage(file);
                                                            formik.handleChange(event);
                                                            uploadWidthHeightImage(file, "HOME0014", toast, dispatch, headers, 'home')
                                                        }}
                                                       onBlur={formik.handleBlur}
                                                    />
                                                </div>
                                                {unlockWorldImage &&formik.touched.HOME0014 && formik.errors.HOME0014 ? (
                                                    <div className='error-msg'>{formik.errors.HOME0014}</div>
                                                ) : null}
                                                <ImagePreview formikError={formik.errors.HOME0014} defaultImage={assets.HOME0014} newImage={unlockWorldImage}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        {/* Header */}
                                        <div className="grid grid-nogutter align-items-center mb-4">
                                            <div className='col-3'>
                                                <label htmlFor="HOME0015" className="cmsLabels">Header</label>
                                            </div>
                                            <div className="col-9">
                                                <div className="file-upload-section">
                                                    <PR.InputText
                                                        id="HOME0015"
                                                        name="HOME0015"
                                                        aria-describedby="HOME0015"
                                                        value={formik.values.HOME0015}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </div>
                                                {formik.touched.HOME0015 && formik.errors.HOME0015 ? (
                                                    <div className='error-msg'>{formik.errors.HOME0015}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* Description */}
                                        <div className="grid grid-nogutter align-items-center mb-4">
                                            <div className='col-3'>
                                                <label htmlFor="HOME0016" className="cmsLabels">Description</label>
                                            </div>
                                            <div className="col-9">
                                                <div className="file-upload-section">
                                                    <PR.InputTextarea autoResize 
                                                    id="HOME0016"
                                                    name="HOME0016"
                                                    aria-describedby="HOME0016"
                                                    value={formik.values.HOME0016}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}/>
                                                </div>
                                                {formik.touched.HOME0016 && formik.errors.HOME0016 ? (
                                                    <div className='error-msg'>{formik.errors.HOME0016}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* Button Name */}
                                        <div className="grid grid-nogutter align-items-center mb-1">
                                            <div className='col-3'>
                                                <label htmlFor="HOME0017" className="cmsLabels">Button Name</label>
                                            </div>
                                            <div className="col-9">
                                                <div className="file-upload-section">
                                                    <PR.InputText
                                                        id="HOME0017"
                                                        name="HOME0017"
                                                        aria-describedby="HOME0017"
                                                        value={formik.values.HOME0017}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </div>
                                                {formik.touched.HOME0017 && formik.errors.HOME0017 ? (
                                                    <div className='error-msg'>{formik.errors.HOME0017}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <PR.Divider />

                    <div className="file-upload">
                        <div>
                            <label htmlFor="panelIcons"><h3 className="mb-3 mt-1">Benefit Section</h3></label>
                            <div className="card">
                                <div className="grid">
                                    <div className="col-6">
                                        {/* Benefit-1 */}
                                        <div className='benefit-box'>
                                            <div className="grid grid-nogutter align-items-center mb-4">
                                                <div className='col-4'>
                                                    <label htmlFor="HOME0018"><b>Header 1</b></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            id="HOME0018"
                                                            name="HOME0018"
                                                            aria-describedby="HOME0018"
                                                            value={formik.values.HOME0018}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.HOME0018 && formik.errors.HOME0018 ? (
                                                        <div className='error-msg'>{formik.errors.HOME0018}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grid grid-nogutter align-items-center mb-4">
                                                <div className='col-4'>
                                                    <label htmlFor="HOME0019"><b>Description 1</b></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                    <PR.InputTextarea autoResize 
                                                    id="HOME0019"
                                                    name="HOME0019"
                                                    aria-describedby="HOME0019"
                                                    value={formik.values.HOME0019}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}/>
                                                    </div>
                                                    {formik.touched.HOME0019 && formik.errors.HOME0019 ? (
                                                        <div className='error-msg'>{formik.errors.HOME0019}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grid grid-nogutter align-items-center">
                                                <div className='col-4'>
                                                    <label htmlFor="HOME0020"><b>Multi-Network Connectivity Icon</b> <br /> <span className='imageFormat'>(png, jpeg, 65W X 65H)</span></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            type="file"
                                                            name="HOME0020"
                                                            className="file-upload-input"
                                                            accept="image/png, image/jpeg"
                                                            onChange={async(event) => {
                                                                const file = event.currentTarget.files[0];
                                                                setBenifitIcon1(file);
                                                                formik.handleChange(event);
                                                                uploadWidthHeightImage(file, "HOME0020", toast, dispatch, headers, 'home')
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        { benifitIcon1 && formik.touched.HOME0020 && formik.errors.HOME0020 ? (
                                                            <div className='error-msg'>{formik.errors.HOME0020}</div>
                                                        ) : null}
                                                        <ImagePreview formikError={formik.errors.HOME0020} defaultImage={assets.HOME0020} newImage={benifitIcon1}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Benefit-2 */}
                                        <div className='benefit-box'>
                                            <div className="grid grid-nogutter align-items-center mb-4">
                                                <div className='col-4'>
                                                    <label htmlFor="HOME0021"><b>Header 2</b></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            id="HOME0021"
                                                            name="HOME0021"
                                                            aria-describedby="HOME0021"
                                                            value={formik.values.HOME0021}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.HOME0021 && formik.errors.HOME0021 ? (
                                                        <div className='error-msg'>{formik.errors.HOME0021}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grid grid-nogutter align-items-center mb-4">
                                                <div className='col-4'>
                                                    <label htmlFor="HOME0022"><b>Description 2</b></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                    <PR.InputTextarea autoResize 
                                                    id="HOME0022"
                                                    name="HOME0022"
                                                    aria-describedby="HOME0022"
                                                    value={formik.values.HOME0022}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}/>
                                                    </div>
                                                    {formik.touched.HOME0022 && formik.errors.HOME0022 ? (
                                                        <div className='error-msg'>{formik.errors.HOME0022}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grid grid-nogutter align-items-center">
                                                <div className='col-4'>
                                                    <label htmlFor="HOME0023"><b>Fast Connections</b> <br /> <span className='imageFormat'>(png, jpeg, 65W X 65H)</span></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            type="file"
                                                            name="HOME0023"
                                                            className="file-upload-input"
                                                            accept="image/png, image/jpeg"
                                                            onChange={async(event) => {
                                                                const file = event.currentTarget.files[0];
                                                                setBenifitIcon2(file);
                                                                formik.handleChange(event);
                                                                uploadWidthHeightImage(file, "HOME0023", toast, dispatch, headers, 'home')

                                                            }}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        { benifitIcon2 && formik.touched.HOME0023 && formik.errors.HOME0023 ? (
                                                            <div className='error-msg'>{formik.errors.HOME0023}</div>
                                                        ) : null}

                                                        <ImagePreview formikError={formik.errors.HOME0023} defaultImage={assets.HOME0023} newImage={benifitIcon2}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Benefit-3 */}
                                        <div className='benefit-box'>
                                            <div className="grid grid-nogutter align-items-center mb-4">
                                                <div className='col-4'>
                                                    <label htmlFor="HOME0024"><b>Header 3</b></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            id="HOME0024"
                                                            name="HOME0024"
                                                            aria-describedby="HOME0024"
                                                            value={formik.values.HOME0024}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.HOME0024 && formik.errors.HOME0024 ? (
                                                        <div className='error-msg'>{formik.errors.HOME0024}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grid grid-nogutter align-items-center mb-4">
                                                <div className='col-4'>
                                                    <label htmlFor="HOME0025"><b>Description 3</b></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                        <PR.InputTextarea autoResize
                                                            id="HOME0025"
                                                            name="HOME0025"
                                                            aria-describedby="HOME0025"
                                                            value={formik.values.HOME0025}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur} />
                                                    </div>
                                                    {formik.touched.HOME0025 && formik.errors.HOME0025 ? (
                                                        <div className='error-msg'>{formik.errors.HOME0025}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grid grid-nogutter align-items-center">
                                                <div className='col-4'>
                                                    <label htmlFor="HOME0026"><b>Support 24/7</b> <br /> <span className='imageFormat'>(png, jpeg, 65W X 65H)</span></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            type="file"
                                                            name="HOME0026"
                                                            className="file-upload-input"
                                                            accept="image/png, image/jpeg"
                                                            onChange={async(event) => {
                                                                const file = event.currentTarget.files[0];
                                                                setBenifitIcon3(file);
                                                                formik.handleChange(event);
                                                                uploadWidthHeightImage(file, "HOME0026", toast, dispatch, headers, 'home')
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {benifitIcon3 && formik.touched.HOME0026 && formik.errors.HOME0026 ? (
                                                            <div className='error-msg'>{formik.errors.HOME0026}</div>
                                                        ) : null}
                                                        <ImagePreview formikError={formik.errors.HOME0026} defaultImage={assets.HOME0026} newImage={benifitIcon3}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Benefit-4 */}
                                        <div className='benefit-box'>
                                            <div className="grid grid-nogutter align-items-center mb-4">
                                                <div className='col-4'>
                                                    <label htmlFor="HOME0027"><b>Header 4</b></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            id="HOME0027"
                                                            name="HOME0027"
                                                            aria-describedby="HOME0027"
                                                            value={formik.values.HOME0027}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.HOME0027 && formik.errors.HOME0027 ? (
                                                        <div className='error-msg'>{formik.errors.HOME0027}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grid grid-nogutter align-items-center mb-4">
                                                <div className='col-4'>
                                                    <label htmlFor="HOME0028"><b>Description 4</b></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                    <PR.InputTextarea autoResize 
                                                     id="HOME0028"
                                                     name="HOME0028"
                                                     aria-describedby="HOME0028"
                                                     value={formik.values.HOME0028}
                                                     onChange={formik.handleChange}
                                                     onBlur={formik.handleBlur}/>
                                                    </div>
                                                    {formik.touched.HOME0028 && formik.errors.HOME0028? (
                                                        <div className='error-msg'>{formik.errors.HOME0028}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grid grid-nogutter align-items-center">
                                                <div className='col-4'>
                                                    <label htmlFor="HOME0029"><b>Easy Installation</b> <br /> <span className='imageFormat'>(png, jpeg, 65W X 65H)</span></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            type="file"
                                                            name="HOME0029"
                                                            className="file-upload-input"
                                                            accept="image/png, image/jpeg"
                                                            onChange={async(event) => {
                                                                const file = event.currentTarget.files[0];
                                                                setBenifitIcon4(file);
                                                                formik.handleChange(event);
                                                                uploadWidthHeightImage(file, "HOME0029", toast, dispatch, headers, 'home')
                                                                
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {benifitIcon4 && formik.touched.HOME0029 && formik.errors.HOME0029 ? (
                                                            <div className='error-msg'>{formik.errors.HOME0029}</div>
                                                        ) : null}

                                                        <ImagePreview formikError={formik.errors.HOME0029} defaultImage={assets.HOME0029} newImage={benifitIcon4}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Benefit-5 */}
                                        <div className='benefit-box'>
                                            <div className="grid grid-nogutter align-items-center mb-4">
                                                <div className='col-4'>
                                                    <label htmlFor="HOME0030"><b>Header 5</b></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            id="HOME0030"
                                                            name="HOME0030"
                                                            aria-describedby="HOME0030"
                                                            value={formik.values.HOME0030}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.HOME0030 && formik.errors.HOME0030? (
                                                        <div className='error-msg'>{formik.errors.HOME0030}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grid grid-nogutter align-items-center mb-4">
                                                <div className='col-4'>
                                                    <label htmlFor="HOME0031"><b>Description 5</b></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                    <PR.InputTextarea autoResize 
                                                     id="HOME0031"
                                                     name="HOME0031"
                                                     aria-describedby="HOME0031"
                                                     value={formik.values.HOME0031}
                                                     onChange={formik.handleChange}
                                                     onBlur={formik.handleBlur}/>
                                                    </div>
                                                    {formik.touched.HOME0031 && formik.errors.HOME0031 ? (
                                                        <div className='error-msg'>{formik.errors.HOME0031}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grid grid-nogutter align-items-center">
                                                <div className='col-4'>
                                                    <label htmlFor="HOME0032"><b>Best Pricing Plans</b> <br /> <span className='imageFormat'>(png, jpeg, 65W X 65H)</span></label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="file-upload-section">
                                                        <PR.InputText
                                                            type="file"
                                                            name="HOME0032"
                                                            className="file-upload-input"
                                                            accept="image/png, image/jpeg"
                                                            onChange={async(event) => {
                                                                const file = event.currentTarget.files[0];
                                                                setBenifitIcon5(file);
                                                                formik.handleChange(event);
                                                                uploadWidthHeightImage(file, "HOME0032", toast, dispatch, headers, 'home')
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {benifitIcon5 && formik.touched.HOME0032 && formik.errors.HOME0032? (
                                                            <div className='error-msg'>{formik.errors.HOME0032}</div>
                                                        ) : null}

                                                        <ImagePreview formikError={formik.errors.HOME0032} defaultImage={assets.HOME0032} newImage={benifitIcon5}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="grid grid-nogutter align-items-center mb-4">
                                            <div className='col-5'>
                                                <label htmlFor="HOME0033" className="cmsLabels">Right section Image <span className='imageFormat'>(png, jpeg, 658W X 768H)</span></label>
                                            </div>
                                            <div className="col-7">
                                                <div className="file-upload-section">
                                                    <PR.InputText
                                                        type="file"
                                                        name="HOME0033"
                                                        className="file-upload-input"
                                                        accept="image/png, image/jpeg"
                                                        onChange={async(event) => {
                                                            const file = event.currentTarget.files[0];
                                                            setBenifitRightImage(file);
                                                            formik.handleChange(event);
                                                            uploadWidthHeightImage(file, "HOME0033", toast, dispatch, headers, 'home')
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {benifitRightImage && formik.touched.HOME0033 && formik.errors.HOME0033 ? (
                                                        <div className='error-msg'>{formik.errors.HOME0033}</div>
                                                    ) : null}
                                                    <ImagePreview formikError={formik.errors.HOME0033} defaultImage={assets.HOME0033} newImage={benifitRightImage}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <PR.Divider />

                    <div className="file-upload">
                        <div>
                            <label htmlFor="partnerSection"><h3 className="mb-4 mt-1">Partner Section</h3></label>
                            <div className="card">
                                <div className="grid">
                                    <div className="col-6">
                                        <div className="grid grid-nogutter align-items-center mb-4">
                                            <div className='col-3'><label htmlFor="HOME0034"><b>Header</b></label></div>
                                            <div className="col-9">
                                                <div className="file-upload-section">
                                                    <PR.InputText
                                                        id="HOME0034"
                                                        name="HOME0034"
                                                        aria-describedby="HOME0034"
                                                        value={formik.values.HOME0034}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </div>
                                                {formik.touched.HOME0034 && formik.errors.HOME0034 ? (
                                                    <div className='error-msg'>{formik.errors.HOME0034}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="grid grid-nogutter align-items-center mb-4">
                                            <div className="col-3">
                                                <label htmlFor="HOME0035"><b>Description</b></label>
                                            </div>
                                            <div className="col-9">
                                                <div className="file-upload-section">
                                                <PR.InputTextarea autoResize 
                                                     id="HOME0035"
                                                     name="HOME0035"
                                                     aria-describedby="HOME0035"
                                                     value={formik.values.HOME0035}
                                                     onChange={formik.handleChange}
                                                     onBlur={formik.handleBlur}/>
                                                </div>
                                                {formik.touched.HOME0035 && formik.errors.HOME0035 ? (
                                                    <div className='error-msg'>{formik.errors.HOME0035}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="grid grid-nogutter align-items-center">
                                            <div className="col-3">
                                                <label htmlFor="HOME0036"><b>Button Name</b></label>
                                            </div>
                                            <div className="col-9">
                                                <div className="file-upload-section">
                                                    <PR.InputText
                                                        id="HOME0036"
                                                        name="HOME0036"
                                                        aria-describedby="HOME0036"
                                                        value={formik.values.HOME0036}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </div>
                                                {formik.touched.HOME0036 && formik.errors.HOME0036 ? (
                                                    <div className='error-msg'>{formik.errors.HOME0036}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="grid grid-nogutter">
                                            <div className="col-3">
                                            </div>
                                            <div className="col-9">
                                                <PR.Button label="Save" type='submit' className="saveBtn" disabled={disableSave}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="grid grid-nogutter align-items-center mb-4">
                                            <div className='col-5'>
                                                <label htmlFor="HOME0037"><b>Right Section Image <span className='imageFormat'>(png, jpeg, 689W X 817H)</span></b></label>
                                            </div>
                                            <div className='col-7'>
                                                <div className="file-upload-section">
                                                    <PR.InputText
                                                        type="file"
                                                        name="HOME0037"
                                                        className="file-upload-input"
                                                        accept="image/png, image/jpeg"
                                                        onChange={async(event) => {
                                                            const file = event.currentTarget.files[0];
                                                            setPartnerRightImage(file);
                                                            formik.handleChange(event);
                                                            uploadWidthHeightImage(file, "HOME0037", toast, dispatch, headers, 'home')
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {partnerRightImage && formik.touched.HOME0037 && formik.errors.HOME0037 ? (
                                                        <div className='error-msg'>{formik.errors.HOME0037}</div>
                                                    ) : null}
                                                    <ImagePreview formikError={formik.errors.HOME0037} defaultImage={assets.HOME0037} newImage={partnerRightImage}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <PR.Toast ref={toast} position="top-right" />
        </div>

    );
};
export default Home;