import React from 'react';
// **** Main CSS **** //
import './assets/scss/main.scss';

import { useSelector } from "react-redux";

import Login from './backoffice/login/Login';
import Users from './backoffice/users/Users';
import Orders from './backoffice/orders/Orders'
import OrderDetails from './backoffice/order-details/OrderDetails';
import { Navigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SiteMaintenance from './backoffice/settings/SiteMaintenance';
import NotFound from './backoffice/not-found/NotFound';
import PriceAdjustment from './backoffice/price-adjustment/PriceAdjustment';
import Dashboard from './backoffice/dashboard/Dashboard';
import PromoCode from './backoffice/promo-code/PromoCode';
import Affiliates from './backoffice/affiliates/Affiliates';
import Cms from './backoffice/cms/Cms';
import PrepaidRetailVouchers from './backoffice/prepaid-retail-vouchers/PrepaidRetailVouchers';


function App() {
  const isAuthAdmin = useSelector(state => state.adminAuth.adminLoggedIn);
  return (
    <Router>
      <Routes>
        <Route path='/' element={ <Navigate to='/login'/>} />
        <Route path='/login' element={!isAuthAdmin ? <Login /> :  <Navigate to='/dashboard' />} />
        <Route path='/dashboard' element={isAuthAdmin ? <Dashboard /> : <Navigate to='/login' />} />
        <Route path='/users' element={isAuthAdmin ? <Users /> : <Navigate to='/login' />} />
        <Route path='/affiliates' element={isAuthAdmin ? <Affiliates /> : <Navigate to='/login' />} />
        <Route path='/orders' element={ isAuthAdmin ? <Orders /> : <Navigate to='/login' /> } />
        <Route path='/orders/:userid' element={isAuthAdmin ? <Orders /> : <Navigate to='/login' /> } />
        <Route path='/user-orders/:userid' element={isAuthAdmin ? <Orders /> : <Navigate to='/login' /> } />
        <Route path='/:screen/details/:orderid' element={ isAuthAdmin ? <OrderDetails /> : <Navigate to='/login' /> } />
        <Route path='/sitemaintenance' element={ isAuthAdmin ? <SiteMaintenance /> : <Navigate to='/login' /> } />
        <Route path='/cms' element={isAuthAdmin ? <Cms /> : <Navigate to='/login' />} />

        <Route path='/price-adjustment' element={ isAuthAdmin ? <PriceAdjustment /> : <Navigate to='/login' /> } />
        <Route path='/vouchers' element={ isAuthAdmin ? <PromoCode /> : <Navigate to='/login' /> } />
        <Route path='/prepaid-retail-vouchers' element={ isAuthAdmin ? <PrepaidRetailVouchers /> : <Navigate to='/login' /> } />
        <Route path='/not-found' element={isAuthAdmin ? <NotFound /> : <Navigate to='/' />} />
        <Route path='*' element={isAuthAdmin ? <Navigate to='/not-found' /> : <Navigate to='/' />} />
      </Routes>
    </Router>
  );
}
export default App;