import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    cms: null,
    disableSave: false
};
const assetDataSlice = createSlice({
    name: 'assetsData',
    initialState: initialState,
    reducers: {
        storeAssets(state, action) {
            state.disableSave = false;
            state.cms = action.payload
        },
        disableSave(state, action) {
            state.disableSave = action.payload
        }
    },
});

export const assetDataActions = assetDataSlice.actions;

export default assetDataSlice.reducer;