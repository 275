import React, { memo } from "react";
import * as PR from "../../../prime-modules/index";
import ChartDataLabels from "chartjs-plugin-datalabels";

const OrdersAndRevenueGraph = ({orderGraph, revenueGraph}) => {
  const labels = orderGraph ? orderGraph.map(value => {
    if(value?.xValue){  
      return value.xValue.split('-')[0]
    } return '';
  }) : [];
    const orderValues = orderGraph ? orderGraph?.map((value) => value.yValue) : [];
  const revenueValues = revenueGraph ? revenueGraph?.map((value) => value.yValue) : [];

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split('-');
    const date = new Date(`${year}-${month}-${day}`);
    return date.toLocaleString('default', { month: 'long', year: 'numeric' });
  };

  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "#495057",
          usePointStyle: true,
          padding: 20,
        },
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function(tooltipItem) {
            const index = tooltipItem[0].dataIndex;
            const originalLabel = orderGraph[index]?.xValue;
            if (originalLabel) {
              return formatDate(originalLabel);
            }
            return '';
          },
          label: function(tooltipItem) {
            const datasetLabel = tooltipItem.dataset.label || '';
            const value = tooltipItem.raw;
            return `${datasetLabel}: ${value}`;
          },
        },
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: '#000',
          font: {
            size: 12,
          },
        },
      },
      y: {
        grid: {
          display: true,
          color: '#e0e0e0',
        },
        ticks: {
          beginAtZero: true,
          stepSize: 5,
          color: '#000',
          font: {
            size: 12,
          },
        },
      },
    },
  };

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Orders',
        data: orderValues,
        borderColor: "#3e95cd",
        backgroundColor: "#003366",
        fill: true,
        tension: 0.4,
        pointBackgroundColor: "#3e95cd",
      },
      {
        label: 'Revenue',
        data: revenueValues,
        borderColor: "#8e5ea2",
        backgroundColor: "#F73859",
        fill: true,
        tension: 0.4,
        pointBackgroundColor: "#8e5ea2",
      },
    ],
  };

  return (
    <PR.Chart
      type="bar"
      data={chartData}
      plugins={[ChartDataLabels]}
      options={lineChartOptions}
    />
  );
};

export default memo(OrdersAndRevenueGraph);

