import React, { useEffect, useState, useMemo } from 'react';
import * as PR from "../../../prime-modules/index";
import { PickList } from 'primereact/picklist';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { uploadAssetsApi } from '../../../services/api';
import { getAllAssets } from '../../../genericFunctions/getAssets';
import { convertArrayToString, convertStringToArray } from '../../../utils';
import { menuConfig } from './MenuConfig';

const Header = ({assets, disableSave}) => {
    const dispatch = useDispatch();
    const toast = React.useRef(null)
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);
    
    const [source, setSource] = useState([]);
    const [target, setTarget] = useState(convertStringToArray(assets?.header?.HEAD0001, assets?.header));
    const appName = process.env?.REACT_APP_NAME;

    useEffect(() => {
        const menuList = menuConfig[appName] ? menuConfig[appName] : menuConfig['data2go'];
        const filteredSource = menuList.filter(itemA => !target.some(itemB => itemB.id === itemA.id)).map(m =>({ id: m.id, name: assets?.labels?.[m.id] ?? m.name}));
        setSource(filteredSource);
    }, [appName, target,  assets?.labels]);

    const itemTemplate = (item) => {
        return (
            <div className="product-item">
                <div className="product-list-detail">
                    <p className="mb-0">{item.name}</p>
                </div>
            </div>
        );
    }

    const handleChange = (event) => {
        const newTarget = event.target;
        if(newTarget?.includes(undefined)) return
        setTarget(newTarget);
    }

    const handleSubmit = () => {
        const resultString = convertArrayToString(target);
        const formData = new FormData();
        formData.append('HEAD0001', JSON.stringify(resultString));
        formData.append('screen', 'header');
        const getCMSResponse = (response) => {
            if (response.result === 'SUCCESS') {
              if(response.data === 'success') {
                toast.current.show({ severity: 'success', summary: 'Success', detail: ' Updated successfully' });
              }
              getAllAssets(toast, dispatch, headers);
            } else if (response.result === "FAILED" && response.error) {
                const error = response.error;
                toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
        };
        uploadAssetsApi(formData, headers, dispatch, getCMSResponse)
    };

    const formik = useFormik({
        initialValues: assets.header,
        onSubmit: handleSubmit
    });

    return (
        <div className='contact'>
            <div className="card">
                <form autoComplete="off" onSubmit={formik.handleSubmit}>
                    <div className="grid grid-nogutter">
                        <div className="col-12">
                            <label htmlFor="sales" className="cmsLabels">Select Menus</label>
                            <div className="col-8">
                                <PickList
                                    source={source}
                                    target={target}
                                    name="HEAD0001"
                                    itemTemplate={itemTemplate}
                                    sourceHeader="Available"
                                    targetHeader="Selected"
                                    sourceStyle={{ height: '342px' }}
                                    targetStyle={{ height: '342px' }}
                                    onChange={handleChange}
                                    filterBy="name"
                                    sourceFilterPlaceholder="Search by name"
                                    targetFilterPlaceholder="Search by name" />
                                {target?.length === 0 ? (
                                    <div className='error-msg'>Please select an option</div>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-nogutter">
                        <div className="col-12">
                            <PR.Button label="Save" type='submit' className="saveBtn" disabled={target?.length === 0 || disableSave }/>
                        </div>
                    </div>
                </form>
            </div>
            <PR.Toast ref={toast} position="top-right" />
        </div>
    );
};
export default Header;
