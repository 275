import React, { memo } from "react";

const TopBundlesMonth = ({ monthGraph, showCurrentMonth }) => {
  if (!monthGraph || monthGraph.length === 0) {
    return <div>No data available</div>;
  }

  const sortByMonthVal = (a, b) => {
    return showCurrentMonth
      ? b.currentMonthVal - a.currentMonthVal
      : b.previousMonthVal - a.previousMonthVal;
  };
  
  const filteredGraph = monthGraph?.filter(item => {
    return showCurrentMonth ? item.currentMonthVal > 0 : item.previousMonthVal > 0
  })    

  const sortedMonthGraph = filteredGraph? filteredGraph.slice().sort(sortByMonthVal) : [];

  const renderPercentageDiff = (percentageDiff) => {
    if (percentageDiff === "+∞") {
      return "+∞";
    } else if (percentageDiff === "-∞") {
      return "-∞";
    } else if (percentageDiff != null) {
      return `${Math.abs(percentageDiff)}%`;
    } else {
      return "";
    }
  };

  return (
    <div className="card right-section">
      <div className="p-6 border-1 border-50 border-round-lg graph-block-bg">
        <div className="block-main">
          <div className="sticky-header">
            <div className="flex align-items-center justify-content-between">
              <h2>Popular Bundles</h2>
            </div>
          </div>
          <div className="mt-3">
            {sortedMonthGraph.length > 0 ? sortedMonthGraph.map((item, index) => (
              <div key={index} className="mb-5 tab-number">
                <div className="flex align-items-center justify-content-between">
                  <div className="left">
                    <span style={{ fontWeight: "bold" }}>{`${item.iso}, `}</span>
                    <span className="description">{`${item.description}`}</span>
                  </div>
                  <div className="right">
                    <ul className="flex align-items-center gap-3">
                      <li>
                        <span>
                          {showCurrentMonth
                            ? item.currentMonthVal
                            : item.previousMonthVal}
                        </span>
                      </li>
                      <li>
                      <div className="percentage">
                          {item.percentageDiff && (
                            <span
                              className={
                                "percentage " +
                                (item.percentageDiff === "+∞" || item.percentageDiff > 0
                                  ? "up"
                                  : item.percentageDiff === "-∞" || item.percentageDiff < 0
                                  ? "down"
                                  : "normal")
                              }
                            >
                              {(item.percentageDiff === "+∞" || item.percentageDiff > 0) && (
                                <i className="pi pi-arrow-up-right" />
                              )}
                              {(item.percentageDiff === "-∞" || item.percentageDiff < 0) && (
                                <i className="pi pi-arrow-down-left" />
                              )}
                              {renderPercentageDiff(item.percentageDiff)}
                            </span>
                          )}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="progressBar">
                  <div
                    className="progress-percentage"
                    style={{
                      width: `${
                        showCurrentMonth
                          ? item.currentMonthVal
                          : item.previousMonthVal
                      }px`,
                      height: "8px",
                      backgroundColor: "#f73759",
                    }}
                  ></div>
                </div>
              </div>
            )) : <>No Bundles Sold this Month</>}
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(TopBundlesMonth);
