import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import * as PR from "../../prime-modules/index";
import AdminFooter from "../layout/admin-footer";
import AdminHeader from "../layout/admin-header";
import { useSelector, useDispatch } from 'react-redux';
import { getPromoCodesList, modifyPromoCode, getAffiliateUsersList } from "../../services/api.jsx";
import { useFormik } from "formik";
import * as Yup from 'yup';
import * as utils from '../../utils';
import "../promo-code/PromoCode.scss";
import moment from "moment";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { parseInt } from "lodash";

const PromoCode = () => {
    const dispatch = useDispatch();
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);
    const toast = useRef();
    const calendarRef = useRef(null);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState("")
    const [loading, setLoading] = useState(false);
    const [promoCodes, setPromoCodes] = useState([]);
    const sortFields = "status";
    const sortField = "createdTs";
    const sortOrder = 1;    
    const [promoCodeError, setPromoCodeError] = useState("");
    const [filterValues, setFilterValues] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const percentage = [
        { name: '%', value: 'percent'},
        { name: '$', value: 'fixed'},
    ];
    const [affiliate, setAffiliate] = useState([])

    const affiliateEmail = ""
    const affiliateStatus = "active"
    const affiliatePhone = ""
    const affiliateName = ""

    const [discountType, setDiscountType] = useState( updateData?.discountAmount ? 'fixed' : 'percent');

    const initialFilters = {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'promoCode': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'percentage': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    }
    const [filters, setFilters] = useState(initialFilters)
    const statusDropdownVals = [
        { label: "ACTIVE", value: "ACTIVE" },
        { label: "EXPIRED", value: "EXPIRED" },
        { label: "PENDING", value: "PENDING" },
        { label: "USED", value: "USED" }
    ]
    const currentDate = new Date()
    const modifyFilterValuesRequest = values => {
        let status = values.status.length > 0 ? values.status.join(',') : '';
        let dates = values.date.length > 0 && values.date.filter(d => d !== null);
        let startTs = dates.length === 2 ? utils.formatDate(dates[0]) : dates.length === 1 ? utils.formatDate(dates[0]) : '';
        let endTs = dates.length === 2 ? utils.formatDate(dates[1]) : dates.length === 1 ? utils.formatDate(dates[0]) : '';
        return { startTs, endTs, status, type:'fixed,percent' };
    }

    const searchFormik = useFormik({
        initialValues: {
            date: "",
            status: ""
        },
        onSubmit: values => setFilterValues(values)         
    })

    const getPromoCodes = useCallback(async (request = { startTs: '', endTs: '', status: '', type: 'percent,fixed' }) => {

        setLoading(true);
        let getResponse = response => {
            if (response.result === "SUCCESS") {
                setLoading(false);
                const promoCodesList = response.data ? response.data : [];
                if (promoCodesList.length > 0) {
                    promoCodesList.map(val => {
                        val.formattedStartTs = moment(val.startTs).format("DD-MM-YYYY, HH:mm:ss");
                        val.formattedEndTs = moment(val.endTs).format("DD-MM-YYYY, HH:mm:ss");
                        val.formattedAllowedUse = val.maxNrOfUses
                        val.calendarStartTs = utils.formatCalendarDate(val.startTs);
                        val.calendarEndTs = utils.formatCalendarDate(val.endTs);   
                        return val
                    })
                } else {
                    setLoading(false);
                    toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'No records found' });
                }
                setPromoCodes(promoCodesList);
            } else {
                setLoading(false);
                const error = response.error;
                toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
        }
        const requestObj = filterValues ? modifyFilterValuesRequest(filterValues) : request
        getPromoCodesList(requestObj, headers, dispatch, getResponse)
    }, [dispatch, filterValues, headers])

    const initialValues = {
        id: updateData ? updateData.id : '',
        affiliate: updateData ? updateData.afId : '',
        promoCode: updateData ? updateData.promoCode : '',
        discount: updateData 
        ? (updateData.discountAmount ? updateData.discountAmount : updateData.discountPercentage)
        : null,
        startTs: updateData ? updateData.startTs: null,
        endTs: updateData ? updateData.endTs: null,
        maxNrOfUses: updateData?.maxNrOfUses !== -1 && updateData?.maxNrOfUses >= 1 ? updateData?.maxNrOfUses : null,
        unlimited: updateData?.maxNrOfUses === -1 || !updateData,
        oncePerUser: updateData.oncePerUser ? updateData.oncePerUser : false
    }

    const validationSchema = Yup.object({
        promoCode: Yup.string()
          .max(12, 'Promo code must be max 12 characters')
          .required('Promo code is required'),
        discount: Yup.number().nullable().required('Discount is required').max(100, 'Value must not exceed 100'),
        maxNrOfUses : Yup.number().when(`unlimited`, {
            is: (value) => !value,
            then: () => Yup.number().typeError('Usage Limit must be a number').required('Usage Limit is required').min(1, 'Usage limit must be at least 1'),
            otherwise: () => Yup.number().nullable(),
        }),
        startTs: Yup.string().trim()
                .required('Start Date is required')
                .nullable(),
        endTs: Yup.string().trim()
                    .required('End Date is required')
                    .nullable(),      
    });

    const getAllAffiliateUsers = useCallback(async () => {
        const sortOrderVal = (sortOrder === 1) ? "asc" : "desc";
        const obj = {
            offset: 0,
            limit: 1000,
            sortField: sortField,
            sortOrder: sortOrderVal,
            name: affiliateName,
            email: affiliateEmail,
            phone: affiliatePhone,
            status: affiliateStatus,
        };
        getAffiliateUsersList(obj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                const list = response.data;
                setAffiliate(list)
            }
        }, [ sortOrder, sortField, headers, dispatch, affiliateEmail, affiliateName, affiliatePhone, affiliateStatus])
    }, [dispatch, headers, sortField, sortOrder])

    useEffect(() => {
        getAllAffiliateUsers()
    }, [getAllAffiliateUsers]);
      

    const handleSubmit = values => {
        let currentDate = (d) => new Date(d)
        if (currentDate(values.startTs) > currentDate(values.endTs)) {
            setPromoCodeError("Start Date should be less than End Date")
            return null
        } else {
            const getResponse = response => {
                const summary = updateData ? 'updated' : 'added';
                if (response.result === "SUCCESS") {
                    setPromoCodeError("")
                    setLoading(false);
                    setVisible(false);
                    getPromoCodes();
                    formik.resetForm();
                    toast.current.show({ severity: 'success', summary: `Voucher details ${summary} successfully` });
                } else {
                    setLoading(false);
                    setPromoCodeError("")
                    const error = response.error;
                    setPromoCodeError(error.errorMsg ? error.errorMsg : error.summary)
                }
            }
            let addRequestObj = {
                promoCode: values.promoCode,
                startTs: moment.utc(values.startTs).format(),
                endTs: moment.utc(values.endTs).format(),
                maxNrOfUses: values.unlimited ? -1 : parseInt(values.maxNrOfUses),
                oncePerUser: values.oncePerUser,
                afId: formik.values.affiliate ? Number(formik.values.affiliate) : null
            }
            if (discountType === 'percent') {
                addRequestObj.discountPercentage = +values.discount;
                addRequestObj.discountType = 'percent';
            } else if (discountType === 'fixed') {
                addRequestObj.discountAmount = +values.discount;
                addRequestObj.discountType = 'fixed';
            }
            const updateRequestObj = {
                id: +values.id,
                promoCode: values.promoCode,
                startTs: moment.utc(values.startTs).format(),
                endTs: moment.utc(values.endTs).format(),
                maxNrOfUses: values.unlimited ? -1 : parseInt(values.maxNrOfUses),
                afId: formik.values.affiliate ? Number(formik.values.affiliate) :  null,
                oncePerUser: values.oncePerUser,
            }

            if (discountType === 'percent') {
                updateRequestObj.discountPercentage = +values.discount;
                updateRequestObj.discountType = 'percent';
            } else if (discountType === 'fixed') {
                updateRequestObj.discountAmount = +values.discount;
                updateRequestObj.discountType = 'fixed';
            }
            const requestObj = updateData ? updateRequestObj : addRequestObj;
            modifyPromoCode(requestObj, headers, dispatch, getResponse);
        }
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: handleSubmit,
        enableReinitialize: true
    })

    useEffect(() => {
        if (updateData) {
            if (updateData.discountAmount) {
                setDiscountType('fixed');
            } else if (updateData.discountPercentage) {
                setDiscountType('percent');
            }
        }
    }, [updateData]);

    
    const status = (rowData) => {
        let status = rowData.status === "ACTIVE"
            ? <span className="status completed">ACTIVE</span>
            : rowData.status === "PENDING"
                ? <span className="status pending">PENDING</span>
                : rowData.status === "EXPIRED"
                    ? <span className="status failed">EXPIRED</span>
                    : rowData.status === "USED"
                        ? <span className="status used">USED</span>
                        : "-"
        return status
    };
    
    const getAffiliatName = (rowData) => {
        const findAffiliate = affiliate.find(a => a.id === rowData.afId);
        return (findAffiliate?.name ?  findAffiliate?.name :(rowData?.afId ?  rowData?.afId : '-'))
    };

    

    const actions = (rowData) => {
        return (
            <>
                <PR.Button
                    icon="pi pi-copy"
                    className="action-btn edit"
                    title="Copy Promocode"
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                        // Copy the promo code to the clipboard
                      navigator.clipboard.writeText(rowData.promoCode)
                        .then(() => {
                            toast.current.show({
                                severity: 'success',
                                summary: 'Success',
                                detail: 'Promo code copied to clipboard!',
                                life: 3000 // Duration in milliseconds for how long the toast will show
                            });                            
                        })
                        .catch(err => {
                            toast.current.show({
                                severity: 'error',
                                summary: 'Error',
                                detail: 'Failed to copy promo code.',
                                life: 3000
                            });                            
                        });
                    }}
                />
                <PR.Button
                    icon="pi pi-pencil"
                    className="action-btn edit"
                    title="Edit"
                    onClick={() => {
                        setVisible(true)
                        setUpdateData(rowData)
                        setPromoCodeError("")
                    }}
                />
            </>
        );
    };

    useEffect(() => {
        getPromoCodes();
    }, [getPromoCodes]);

    const renderDiscount = (rowData) => { 
        return rowData.discountType === 'percent' ? (rowData.discountPercentage + ' %') : utils.setPrice(rowData.discountAmount);
    }
    const formattedStartTs = (rowData) => rowData.formattedStartTs
    const formattedEndTs = (rowData) => rowData.formattedEndTs
    const formattedAllowedUse = (rowData) =>  rowData.formattedAllowedUse === -1 ? 'Unlimited' : rowData.formattedAllowedUse;

    const gloabalSearchHandler = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
 
        setFilters(_filters);
        setSearchValue(value);
    }


    const promoCodeHandler = e => {
        let cleaned =  (formik.values.voucherCount > 1 && e.target.value > 15) ? e.target.value.replace(/[^0-9]/g, '').substring(0, 15) : e.target.value;
        formik.setFieldValue('promoCode', cleaned)
        setPromoCodeError("");
    }

    const discountHandler = (e) => {
        let discountValue = e.value;
        if (discountValue === null || /^[0-9\b]+$/.test(discountValue)) {
            formik.setFieldValue('discount', e.value); 
        }
    };

    const affiliateChange = (e) => {
        const afID = e.value;  // e.value contains the selected affiliate's afId
        formik.setFieldValue('affiliate', afID);
    };
    
    const handleAffiliateOptions = (customers = []) => {
        return (Array.isArray(customers) ? customers : []).map(customer => ({
            label: customer.name,  
            value: customer.id
        }));
    };

    const dateHandler = (e, fieldName) => {
        setPromoCodeError("");
        formik.setFieldValue(fieldName, moment(e.value).utc().format()); 
    }; 
    
    const maxUsesHandler = e => {
        let maxUses = e.value;
        if (!formik.values.unlimited) {
        if (maxUses !== null || /^[0-9\b]+$/.test(maxUses)) {
            formik.setFieldValue("maxNrOfUses", maxUses);
        }        
       
        }
    }

    const handleUsageLimitChange = (e) => {
        const checked = e.target.checked;
       
        formik.setFieldValue("unlimited", checked );
    };

    const handleOncePerUser = (e) => {
        formik.setFieldValue('oncePerUser', e.target.checked)
    }

 

    return (
        <>
            <div className="main">
                <div className="layout-sidebar">
                    <AdminHeader />
                </div>
                <div className="layout-content-wrapper">
                    <section className="admin-users-section promo-code-section">
                        <div className="grid grid-nogutter">
                            <div className="col-12">
                                <div className="heading-sec">
                                    <div className="flex align-items-center justify-content-between mb-4">
                                        <h1>Discount Vouchers</h1>
                                        <div className="right-section flex align-items-center gap-3">
                                        </div>
                                    </div>
                                    <div className="flex align-items-center justify-content-between filter-right">
                                            <div className="flex">
                                                <form onSubmit={searchFormik.handleSubmit} className="flex align-items-center gap-2">
                                                    <span className="p-input-icon-right search-field">
                                                        <i className="pi pi-search" />
                                                        <PR.InputText placeholder="Search" type="search" value={searchValue} onChange={gloabalSearchHandler} />
                                                    </span>
                                                    <PR.Calendar
                                                        ref={calendarRef}
                                                        value={searchFormik.values.date}
                                                        onChange={(e) => {
                                                            searchFormik.handleChange(e);
                                                            const selectedDates = e.value;
                                                            const validSelectedDates = selectedDates && selectedDates.filter(date => date !== null);
                                                            if (validSelectedDates?.length === 2) {
                                                                calendarRef.current.hide(); // Close the calendar popup
                                                            }
                                                        }}
                                                        selectionMode="range"
                                                        readOnlyInput
                                                        placeholder="Date range"
                                                        name="date"
                                                        dateFormat="dd-mm-yy"
                                                        showIcon
                                                        showButtonBar
                                                        numberOfMonths={2}
                                                        onClearButtonClick={() => searchFormik.setFieldValue("date", [])}
                                                    />
                                                    <PR.MultiSelect
                                                        placeholder="Status"
                                                        options={statusDropdownVals}
                                                        optionLabel="label"
                                                        optionValue="value"
                                                        name="status"
                                                        value={searchFormik.values.status}
                                                        onChange={searchFormik.handleChange}
                                                        maxSelectedLabels={1}
                                                    />
                                                    <PR.Button type="submit" label="Search" className="searchBtn" />
                                                    <PR.Button type="reset" label="Reset" className="resetBtn" onClick={() => {
                                                        searchFormik.handleReset();
                                                        setFilterValues("");
                                                        setSearchValue("");
                                                        setFilters(initialFilters);
                                                    }} />
                                                </form>
                                            </div>
                                            <div className="right-section flex align-items-center gap-3">
                                            <PR.Button label="Create new promo" className="export-button" icon="pi pi-plus" iconPos="left"  onClick={() => { setVisible(true); setUpdateData(""); setPromoCodeError(""); }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="users-data-table promocode-table card">
                            <PR.DataTable
                                loading={loading}
                                value={promoCodes}
                                paginator
                                responsiveLayout="scroll"
                                paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                paginatorDropdownAppendTo={"self"}
                                rows={50}
                                let-i="rowIndex"
                                filters={filters}
                                globalFilterFields={['promoCode', 'percentage']}
                                rowsPerPageOptions={[10, 20, 50]}
                                sortField={sortFields}
                                sortOrder={sortOrder}
                                // onSort={(e) => {
                                //     setSortFields(e.sortField);
                                //     setSortOrder(e.sortOrder);
                                // }}
                                >
                                <PR.Column field="promoCode" header="Promo code" sortable className="promocode-column"></PR.Column>
                                <PR.Column field="discountPercentage" header="Discount Value" body={renderDiscount}></PR.Column>
                                <PR.Column field="startTs" header="Start Date" body={formattedStartTs} sortable></PR.Column>
                                <PR.Column field="endTs" header="End Date" body={formattedEndTs} sortable></PR.Column>
                                <PR.Column field="maxNrOfUses" header="Limit" body={formattedAllowedUse} sortable></PR.Column>
                                <PR.Column field="nrOfUses" header="Usage Count" sortable></PR.Column>
                                <PR.Column header="Status" field="status" sortable body={status} ></PR.Column>
                                <PR.Column header="Affiliate" field="affiliate" sortable body={getAffiliatName}></PR.Column>
                                <PR.Column body={actions} header="Actions"></PR.Column>
                            </PR.DataTable>
                        </div>
                    </section>
                    {/* Create new promo - Dialog */}
                    <PR.Dialog 
                        header={(updateData ? "Update" : "Create New") + " Promo Code"}
                        visible={visible}
                        draggable={false}
                        resizable={false}
                        onHide={() => { setVisible(false); formik.resetForm(); }}                        
                        dismissableMask={true}
                        maskClassName='create-voucher-dialog-mask'
                        className="filter-dialog voucher-modal create-promo-dialog">
                        <div className="filter-content">
                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <div className="grid mt-2">
                                <div className="col-12 md:col-6 pr-1">
                                    <p className="mb-3">Enter promo code <span>(Max: 12 characters)</span></p>
                                    <PR.InputText name="promoCode" value={formik.values.promoCode} onChange={promoCodeHandler} onBlur={formik.handleBlur} className="w-full" />
                                    {formik.errors.promoCode && formik.touched.promoCode
                                    ? <div className='error-message'>{formik.errors.promoCode}</div>
                                    : ''
                                }
                                </div>
                                <div className="col-12 md:col-6 pl-1">
                                    <p className="mb-3">Affiliate ID <span>(Optional)</span></p>
                                    <PR.Dropdown name="affiliate" filter showClear={true}  placeholder="Select Affiliate"  value={formik.values.affiliate || ""} onChange={affiliateChange} options={handleAffiliateOptions(affiliate)} className="w-full" />
                                </div>
                            </div>
                            <div className="grid mt-2">
                                <div className="col-12 pb-1">
                                    <p className="mb-0">Value & Usage</p>
                                </div>
                                <div className="col-12">
                                    <div className='grid align-items-center'>
                                        <div className="col-12 md:col-4">
                                            <div className="relative icon-input mt-1">
                                                <PR.InputNumber name="discount" value={formik.values.discount || null} onChange={discountHandler} onBlur={formik.handleBlur} className="w-full" placeholder="Discount value" />
                                                <PR.Dropdown value={discountType} onChange={(e) => setDiscountType(e.value)} options={percentage} optionLabel="name" placeholder="%" className="discount-dropdown" disabled={updateData} />
                                                {formik.errors.discount && formik.touched.discount
                                                    ? <div className='error-message'>{formik.errors.discount}</div>
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                        

                                        <div className="col-12 md:col-5">
                                            <div className="relative icon-input mt-0">
                                                <div className="flex align-items-center">
                                                    <label className="flex align-items-center">
                                                        <input
                                                            type="checkbox" 
                                                            checked={formik.values.unlimited} 
                                                            onChange={handleUsageLimitChange} 
                                                            className="usage-limit-checkbox"
                                                        />
                                                        <span>Unlimited</span>
                                                    </label>&nbsp;  
                                                    <i className="pi pi-info-circle unlimited-tooltip relative-tooltip"></i>
                                                    <PR.Tooltip target=".unlimited-tooltip">
                                                        <span>Set number of time the promo code can be used to unlimited </span>
                                                    </PR.Tooltip>
                                                   
                                                        <div className="relative icon-input mt-1 unlimited-input">
                                                            <PR.InputNumber
                                                            disabled = {formik.values.unlimited ? true : false}
                                                                name="maxNrOfUses"
                                                                value={formik.values.maxNrOfUses || null} 
                                                                onChange={maxUsesHandler}
                                                                onBlur={formik.handleBlur}
                                                                min={1}
                                                                className="w-full"
                                                                placeholder="Usage limit"
                                                            />
                                                            <i className="pi pi-info-circle usage-limit-tooltip"></i>

                                                            <PR.Tooltip target=".usage-limit-tooltip">
                                                                <span>Number of time the promo code can be used.</span>
                                                            </PR.Tooltip>
                                                            {formik.errors.maxNrOfUses && formik.touched.maxNrOfUses
                                                                    ? <div className='error-message'>{formik.errors.maxNrOfUses}</div>
                                                                    : ''
                                                                }
                                                        </div>
                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 md:col-3">
                                            <div className="flex align-items-center icon-input">
                                                <label className="flex align-items-center">
                                                    <input
                                                        type="checkbox" 
                                                        checked={formik.values.oncePerUser} 
                                                        onChange={handleOncePerUser} 
                                                        className="usage-limit-checkbox"
                                                    />
                                                    <span>One time/user</span>
                                                </label>&nbsp;
                                                <i className="pi pi-info-circle onetime-tooltip relative-tooltip"></i>
                                                <PR.Tooltip target=".onetime-tooltip">
                                                    <span>The promo code can be used to be one time by the user</span>
                                                </PR.Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid mt-2">
                                <div className="col-12 pb-1">
                                    <p className="mb-0">Duration</p>
                                </div>
                                <div className="col-12">
                                    <div className='grid align-items-center'>
                                        <div className="col-6">
                                            <PR.Calendar 
                                                name="startTs"
                                                value={formik.values.startTs ? moment(formik.values.startTs).toDate() : null} 
                                                onChange={(e) => dateHandler(e, 'startTs')}  
                                                onBlur={formik.handleBlur}
                                                dateFormat="dd/mm/yy" 
                                                showIcon 
                                                minDate={currentDate}  
                                                placeholder="Start date" 
                                            />
                                            {formik?.errors?.startTs && formik?.touched?.startTs && formik.values.startTs === null && (
                                                <div className='error-message'>{formik?.errors?.startTs}</div>
                                            )}
                                        </div>
                                        <div className="col-6">
                                            <PR.Calendar 
                                                name="endTs"
                                                value={formik.values.endTs ? moment(formik.values.endTs).toDate() : null} 
                                                onChange={(e) => dateHandler(e, 'endTs')}  
                                                onBlur={formik.handleBlur}
                                                showIcon 
                                                minDate={currentDate}
                                                dateFormat="dd/mm/yy" 
                                                placeholder="End date" 
                                            />
                                            {formik.errors?.endTs && formik.touched?.endTs && formik.values?.endTs === null && (
                                                <div className='error-message'>{formik.errors.endTs}</div>
                                            )}
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                {promoCodeError && <div className='error-message'>{promoCodeError}</div>}
                                <div className="buttons-sections flex align-items-center mt-4">
                                    <PR.Button  label={updateData ? "UPDATE" : "Create"}  type="submit" className="confirm-button w-full"/>
                                </div>
                            </form>
                        </div>
                    </PR.Dialog>
                    <PR.Toast ref={toast} position='top-right' />
                    <AdminFooter />
                </div>
            </div>
        </>
    );

};

export default PromoCode;