import React from 'react';
import { useState, useMemo } from 'react';
import * as PR from "../../../prime-modules/index";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { uploadAssetsApi } from "../../../services/api"
import { useDispatch, useSelector } from 'react-redux';
import "./Contact.scss";
import { trimFormData, validateTextField } from '../../../utils';
import { getAllAssets } from '../../../genericFunctions/getAssets';
import ImagePreview from '../generic/imagePreview';
import { uploadWidthHeightImage } from '../../../utils/reuse';

const Contact = ({assets, disableSave}) => {
    const dispatch = useDispatch();
    const toast = React.useRef(null)
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);

    const [salesImage, setSalesImage] = useState(null)
    const [callsImage, setCallsImage] = useState(null)
    const [supportImage, setSupportImage] = useState(null)


    const validationSchema = Yup.object().shape({
        CON0001: validateTextField(10, 30, 'Top Header'),
        CON0002: validateTextField(90, 170, 'Top Header Description'),
        CON0004: validateTextField(4, 20, 'Sales Header'),
        CON0005: validateTextField(20, 100, 'Sales Description'),
        CON0006: Yup.string().trim().email("Invalid Email").required("Email required"),
        CON0008: validateTextField(4, 20, 'Calls Header'),
        CON0009: validateTextField(20, 100, 'Calls Description'),
        CON0010: validateTextField(10, 50, 'phone'),
        CON0012: validateTextField(4, 20, 'Support Header'),
        CON0013: validateTextField(20, 100, 'Support Description'),
        CON0014: Yup.string().trim().email("Invalid Email").required("Email required")
    });

    const handleSubmit = async (data) => {
        const formData = new FormData();
        const trimmedFormData = trimFormData(data);
        formData.append('screen', 'contact');
        for (const field in trimmedFormData) {
            if (trimmedFormData.hasOwnProperty(field)) {
                const value = trimmedFormData[field];
                if (value && typeof value === 'string' && !(value.startsWith('iVBORw0KGgoAAAANSUhEUg') || value.startsWith('/9j/4AAQSkZJRgA'))) {
                    formData.append(field, value);
                }
            } 
        }        
        const getCMSResponse = (response) => {
            if (response.result === 'SUCCESS') {
                if (response.data === 'success') {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: ' Updated successfully' });
                }
                getAllAssets(toast, dispatch, headers);
            } else if (response.result === "FAILED" && response.error) {
                const error = response.error;
                toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
        };
        uploadAssetsApi(formData, headers, dispatch, getCMSResponse);
    }

    const formik = useFormik({
        initialValues: assets,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true, 
    })

    return (
        <div className='contact'>
            <div className="card">
                {/* <h2>Contact Settings</h2> */}
                <form autoComplete="off" onSubmit={formik.handleSubmit} >
                    <div className="imageFormat mb-4 ">
                        <strong>(Image Size Should not exceed 1MB)</strong>
                    </div>
                    <div className="grid grid-nogutter align-items-center mb-4 mt-4">
                        <div className='col-2'>
                            <label htmlFor="CON0001" className="cmsLabels">Top Header</label>
                        </div>
                        <div className="col-5">
                            <div className="file-upload-section">
                                <PR.InputText
                                    id="CON0001"
                                    name="CON0001"
                                    aria-describedby="CON0001"
                                    value={formik.values.CON0001}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            {formik.touched.CON0001 && formik.errors.CON0001 ? (
                                <div className='error-msg'>{formik.errors.CON0001}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="grid grid-nogutter align-items-center mb-4">
                        <div className='col-2'>
                            <label htmlFor="CON0002" className="cmsLabels">Top Header Description</label>
                        </div>
                        <div className="col-5">
                            <div className="file-upload-section">
                                <PR.InputTextarea autoResize
                                   id="CON0002"
                                   name="CON0002"
                                   aria-describedby="CON0002"
                                   value={formik.values.CON0002}
                                   onChange={formik.handleChange}
                                   onBlur={formik.handleBlur} />
                            </div>
                            {formik.touched.CON0002 && formik.errors.CON0002 ? (
                                <div className='error-msg'>{formik.errors.CON0002}</div>
                            ) : null}
                        </div>
                    </div>

                    <PR.Divider />
                    <div className="support-section">
                        <div className="col-12">
                            <label htmlFor="panelsSection"><h3 className="mb-1 mt-1">Support Section</h3></label>
                        </div>
                        <div className='grid'>
                            <div className="col-2"></div>
                            <div className="col">
                                <div className="col-12 text-center">
                                    <h4 className="mb-2 mt-0">Sales</h4>
                                </div>
                            </div>
                            <div className="col">
                                <div className="col-12 text-center">
                                    <h4 className="mb-2 mt-0">WhatsApp </h4>
                                </div>
                            </div>
                            <div className="col">
                                <div className="col-12 text-center">
                                    <h4 className="mb-2 mt-0">Support</h4>
                                </div>
                            </div>
                        </div>
                        <div className="grid align-items-center">
                            <div className="col-2">
                                <div className="col-12 mb-4">
                                    <label htmlFor="CON0003" className="cmsLabels">Image <br /><span className='imageFormat'>(png, jpeg, 100W X 100H)</span></label>
                                </div>
                                <div className="col-12 mb-4">
                                    <label htmlFor="CON0004" className="cmsLabels">Header</label>
                                </div>
                                <div className="col-12 mb-4">
                                    <label htmlFor="CON0005" className="cmsLabels">Description</label>
                                </div>
                                <div className="col-12 mb-4"></div>
                            </div>

                            <div className="col">
                                <div className="panel-card">
                                    <div className="grid grid-nogutter align-items-center mb-3">
                                        <div className="col-12">
                                            <div className="file-upload-section">
                                                <PR.InputText
                                                    type="file"
                                                    name="CON0003"
                                                    className="file-upload-input"
                                                    accept="image/png, image/jpeg"
                                                    onChange={async(event) => {
                                                        const file = event.currentTarget.files[0];
                                                        setSalesImage(file);
                                                        formik.handleChange(event);
                                                        uploadWidthHeightImage(file, "CON0003", toast, dispatch, headers, 'contact')
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {salesImage && formik.touched.CON0003 && formik.errors.CON0003 ? (
                                                    <div className='error-msg'>{formik.errors.CON0003}</div>
                                                ) : null}

                                                <ImagePreview formikError={formik.errors.CON0003} defaultImage={assets?.CON0003} newImage={salesImage}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid grid-nogutter align-items-center mb-3">
                                        <div className="col-12">
                                            <div className="file-upload-section">
                                                <PR.InputText
                                                    id="CON0004"
                                                    name="CON0004"
                                                    aria-describedby="CON0004"
                                                    value={formik.values.CON0004}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </div>
                                            {formik.touched.CON0004 && formik.errors.CON0004 ? (
                                                <div className='error-msg'>{formik.errors.CON0004}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="grid grid-nogutter align-items-center mb-3">
                                        <div className="col-12">
                                            <div className="file-upload-section">
                                                <PR.InputText
                                                    id="CON0005"
                                                    name="CON0005"
                                                    aria-describedby="CON0005"
                                                    value={formik.values.CON0005}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </div>
                                            {formik.touched.CON0005 && formik.errors.CON0005 ? (
                                                <div className='error-msg'>{formik.errors.CON0005}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="grid grid-nogutter align-items-center">
                                        <div className="col-12">
                                            <div className="file-upload-section">
                                                <PR.InputText
                                                    id="CON0006"
                                                    name="CON0006"
                                                    aria-describedby="CON0006"
                                                    value={formik.values.CON0006}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    placeholder='Email'
                                                />
                                            </div>
                                            {formik.touched.CON0006 && formik.errors.CON0006 ? (
                                                <div className='error-msg'>{formik.errors.CON0006}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col">
                                <div className="panel-card">
                                    <div className="grid grid-nogutter align-items-center mb-3">
                                        <div className="col-12">
                                            <div className="file-upload-section">
                                                <PR.InputText
                                                    type="file"
                                                    name="CON0007"
                                                    className="file-upload-input"
                                                    accept="image/png, image/jpeg"
                                                    onChange={async(event) => {
                                                        const file = event.currentTarget.files[0];
                                                        setCallsImage(file);
                                                        formik.handleChange(event);
                                                        uploadWidthHeightImage(file, "CON0007", toast, dispatch, headers, 'contact')
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {callsImage && formik.touched.CON0007 && formik.errors.CON0007 ? (
                                                    <div className='error-msg'>{formik.errors.CON0007}</div>
                                                ) : null}

                                                <ImagePreview formikError={formik.errors.CON0007} defaultImage={assets?.CON0007} newImage={callsImage}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid grid-nogutter align-items-center mb-3">
                                        <div className="col-12">
                                            <div className="file-upload-section">
                                                <PR.InputText
                                                    id="CON0008"
                                                    name="CON0008"
                                                    aria-describedby="CON0008"
                                                    value={formik.values.CON0008}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </div>
                                            {formik.touched.CON0008 && formik.errors.CON0008 ? (
                                                <div className='error-msg'>{formik.errors.CON0008}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="grid grid-nogutter align-items-center mb-3">
                                        <div className="col-12">
                                            <div className="file-upload-section">
                                                <PR.InputText
                                                    id="CON0009"
                                                    name="CON0009"
                                                    aria-describedby="CON0009"
                                                    value={formik.values.CON0009}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </div>
                                            {formik.touched.CON0009 && formik.errors.CON0009 ? (
                                                <div className='error-msg'>{formik.errors.CON0009}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="grid grid-nogutter">
                                        <div className="col-12">
                                            <div className="file-upload-section">
                                                <PR.InputText
                                                    id="CON0010"
                                                    name="CON0010"
                                                    aria-describedby="CON0010"
                                                    value={formik.values.CON0010}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    placeholder='Contact Number'
                                                />
                                            </div>
                                            {formik.touched.CON0010 && formik.errors.CON0010 ? (
                                                <div className='error-msg'>{formik.errors.CON0010}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col">
                                <div className="panel-card">
                                    <div className="grid grid-nogutter align-items-center mb-3">
                                        <div className="col-12">
                                            <div className="file-upload-section">
                                                <PR.InputText
                                                    type="file"
                                                    name="CON0011"
                                                    className="file-upload-input"
                                                    accept="image/png, image/jpeg"
                                                    onChange={async(event) => {
                                                        const file = event.currentTarget.files[0];
                                                        setSupportImage(file);
                                                        formik.handleChange(event);
                                                        uploadWidthHeightImage(file, "CON0011", toast, dispatch, headers, 'contact')
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {supportImage && formik.touched.CON0011 && formik.errors.CON0011 ? (
                                                    <div className='error-msg'>{formik.errors.CON0011}</div>
                                                ) : null}
                                                <ImagePreview formikError={formik.errors.CON0011} defaultImage={assets?.CON0011} newImage={supportImage}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid grid-nogutter align-items-center mb-3">
                                        <div className="col-12">
                                            <div className="file-upload-section">
                                                <PR.InputText
                                                    id="CON0012"
                                                    name="CON0012"
                                                    aria-describedby="CON0012"
                                                    value={formik.values.CON0012}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </div>
                                            {formik.touched.CON0012 && formik.errors.CON0012 ? (
                                                <div className='error-msg'>{formik.errors.CON0012}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="grid grid-nogutter align-items-center mb-3">
                                        <div className="col-12">
                                            <div className="file-upload-section">
                                                <PR.InputText
                                                    id="CON0013"
                                                    name="CON0013"
                                                    aria-describedby="CON0013"
                                                    value={formik.values.CON0013}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </div>
                                            {formik.touched.CON0013 && formik.errors.CON0013 ? (
                                                <div className='error-msg'>{formik.errors.CON0013}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="grid grid-nogutter align-items-center">
                                        <div className="col-12">
                                            <div className="file-upload-section">
                                                <PR.InputText
                                                    id="CON0014"
                                                    name="CON0014"
                                                    aria-describedby="CON0014"
                                                    value={formik.values.CON0014}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    placeholder='Support Email'
                                                />
                                            </div>
                                            {formik.touched.CON0014 && formik.errors.CON0014 ? (
                                                <div className='error-msg'>{formik.errors.CON0014}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <PR.Divider />

                    <div className="grid grid-nogutter">
                        <div className="col-12">
                            <PR.Button label="Save" type='submit' className="saveBtn" disabled={disableSave} />
                        </div>
                    </div>
                </form>
            </div>
            <PR.Toast ref={toast} position="top-right" />
        </div>
    );
};
export default Contact;