import React, { memo, useEffect, useRef} from "react";
import BundlesSoldBarGraph from "./graphs/bundlesSoldBarGraph";
import TopBundlesMonth from "./graphs/topBundlesMonth";
import OrdersPerDayGraph from "./graphs/ordersPerDayGraph";

const GraphsBlock = (props) => {
  const graphContainerRef = useRef(null);
  const graphList = props ? props.selectedGraphs : [];
  const monthGraph = props ? props.selectedMonthGraph : [];
  const orderGraph = props ? props.selectedOrderGraphs : {};
  const revenueGraph = props ? props.selectedRevenueGraphs : {};
  const showCurrentMonth = props ? props.currentMonthButton : false

  useEffect(() => {
    if (graphContainerRef.current) {
      graphContainerRef.current.scrollTop = graphContainerRef.current.scrollHeight;
    }
  }, [showCurrentMonth, props?.selectedGraphs]);

  return (
    <>
      <div className="grid mt-5">

      {/* Bundles Sold Section */}
        {graphList ? (
          <div className="col-6">
            <div className="card right-section">
              <div className="p-3 border-1 border-50 border-round graph-block-bg" ref={graphContainerRef}> 
                <div className="sticky-header">
                  <div className="flex align-items-center justify-content-between block-header">
                    <h2>Country Wise Bundles Sold</h2>
                  </div>
                </div>
                <div className="flex align-items-center block-main mt-4">
                  <div className="card w-12">
                    {graphList.id === "bundlesSold" && (
                      <BundlesSoldBarGraph
                        graph={graphList}
                        data={
                          showCurrentMonth
                            ? graphList.currentMonthValues
                            : graphList.previousMonthValues
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-6">No graphs were selected to display</div>
        )}

        {/* Bundles Sold This Month Section */}
        <div className="col-6">
          <TopBundlesMonth monthGraph={monthGraph} showCurrentMonth={showCurrentMonth} />
        </div>
        
        {/* Orders & Revenue Per Day Section */}
        <div className="col-6 mt-3">
          {orderGraph && revenueGraph ? (
            <div className="card right-section">
              <div className="p-3 border-1 border-50 border-round graph-block-bg" ref={graphContainerRef}>
                <div className="sticky-header">
                  <div className="flex align-items-center justify-content-between block-header">
                    <h2>Orders & Revenue Per Day</h2>
                  </div>
                </div>
                <div className="mt-4">
                  <OrdersPerDayGraph
                    orderGraph={
                      showCurrentMonth
                        ? orderGraph.currentMonthValues
                        : orderGraph.previousMonthValues
                    }
                    revenueGraph={
                      showCurrentMonth
                        ? revenueGraph.currentMonthValues
                        : revenueGraph.previousMonthValues
                    }
                  />
                </div>
              </div>
            </div>
            ) : (
              <div className="col-6 mt-3">No graphs were selected to display</div>
            )}
        </div>

      </div>
    </>
  );
};

export default memo(GraphsBlock);
